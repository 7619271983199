import React, { useEffect, useState } from "react";
import { delteUser, getUsers } from "../../api/user";
import FormTextField from "../../components/Fields/FormTextField";
import Pagination from "@choc-ui/paginator";
import {
  IconButton,
  Flex,
  Tooltip,
  Stack,
  Icon,
  Text,
  useDisclosure,
  TableContainer,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Button,
  HStack,
  AlertDialog,
  AlertDialogFooter,
  AlertDialogBody,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  useToast,
} from "@chakra-ui/react";
import { CheckIcon, DeleteIcon, CloseIcon } from "@chakra-ui/icons";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useFormik } from "formik";
import { emailInvitationValidationSchema } from "../../validation/validation";
import {
  sendAcceptanceEmail,
  sendInvitationEmail,
} from "../../api/verification";

function Users() {
  const [users, setUsers] = useState([]);
  const emailInvitationForm = useFormik({
    initialValues: {
      email: "",
    },
    onSubmit: (values) => {
      sendInvitationEmail(values.email)
        .then((res) => {
          setInvitationsCount(invitationsCount + 1);
          toast({
            title: res.data.data,
            status: "success",
            duration: 3000,
            isClosable: true,
            position: "top",
          });
          emailInvitationForm.resetForm();
        })
        .catch((err) => {
          toast({
            title: err.response.data.error,
            status: "error",
            duration: 3000,
            isClosable: true,
            position: "top",
          });
          emailInvitationForm.resetForm();
        });
    },
    validationSchema: emailInvitationValidationSchema,
  });
  const [acceptanceEmailLoading, setAcceptanceEmailLoading] = useState(false);

  const handleInvitationEmailForRegisteredUser = (email) => {
    setAcceptanceEmailLoading(true);
    sendAcceptanceEmail(email)
      .then((res) => {
        toast({
          title: res.data.data,
          status: "success",
          duration: 3000,
          isClosable: true,
          position: "top",
        });
        setAcceptanceEmailLoading(false);
      })
      .catch((err) => {
        toast({
          title: err.response.data.error,
          status: "error",
          duration: 3000,
          isClosable: true,
          position: "top",
        });
        setAcceptanceEmailLoading(false);
      });
  };

  const [searchParams, setSearchParams] = useSearchParams();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = React.useRef();
  const navigate = useNavigate();
  const [userToBeDeleted, setUserToBeDeleted] = useState(null);
  const [page, setPage] = useState(searchParams.get("page") || 1);
  const [count, setCount] = useState(0);
  const toast = useToast();
  const [invitationsCount, setInvitationsCount] = useState(0);
  const perPage = 10;
  useEffect(() => {
    getUsers(page, perPage)
      .then((res) => {
        setUsers(res.data.data.users);
        setCount(res.data.data.usersCount);
        setInvitationsCount(res.data.data.invitedEmailsCount);
      })
      .catch((err) => {});
  }, [isOpen, page, count, acceptanceEmailLoading]);

  return (
    <Flex p={10} w={"100%"} h={"100%"} direction={"column"}>
      <br />
      <br />
      <HStack spacing={4} justifyContent={"space-between"}>
        <Text fontSize="3xl">Users</Text>
        {/* <Button
          bg={"black"}
          color={"white"}
          _hover={{
            bg: "gray.800",
          }}
          onClick={() => {
            navigate("/users/add-user");
          }}
        >
          Add User
        </Button> */}
        <Flex p={5} direction={"column"}>
          <Text>
            Total Users: <b>{count}</b>
          </Text>
          <Text>
            Invited Users: <b>{invitationsCount}</b>
          </Text>
        </Flex>
      </HStack>
      <Stack
        spacing={4}
        mt={10}
        direction={"row"}
        w={"100%"}
        justify={"flex-end"}
        align={"center"}
      >
        <FormTextField
          label={"Invitee's Email"}
          type={"email"}
          name={"email"}
          placeholder={"Enter email"}
          error={emailInvitationForm.errors.email}
          touched={emailInvitationForm.touched.email}
          value={emailInvitationForm.values.email}
          onChange={emailInvitationForm.handleChange}
          helperText={"Enter the email of the user you want to invite"}
        />
        <Button
          bg={"black"}
          color={"white"}
          _hover={{
            bg: "gray.800",
          }}
          onClick={() => {
            emailInvitationForm.handleSubmit();
          }}
        >
          Invite
        </Button>
      </Stack>

      <TableContainer
        p={5}
        mt={10}
        borderWidth={1}
        borderRadius={8}
        width={"100%"}
      >
        <Table colorScheme="gray" size="sm">
          <Thead>
            <Tr>
              <Th>User</Th>
              <Th>Email</Th>
              <Th>Is Verified</Th>
              <Th>Is Invited</Th>
              <Th>Phone Number</Th>
              <Th>Country</Th>
              <Th>Job Title</Th>
              <Th>Company</Th>
              <Th>Actions</Th>
            </Tr>
          </Thead>
          <Tbody>
            {users.length > 0 &&
              users.map((user) => (
                <Tr key={user._id}>
                  <Td>{user.name}</Td>
                  <Td>{user.email}</Td>
                  <Td>
                    {user.isEmailVerified ? (
                      <CheckIcon color={"green.300"} />
                    ) : (
                      <CloseIcon color={"red.300"} />
                    )}
                  </Td>
                  <Td>
                    {user.isInvited ? (
                      <CheckIcon color={"green.300"} />
                    ) : (
                      <CloseIcon color={"red.300"} />
                    )}
                  </Td>
                  <Td>{user.phoneNumber ? user.phoneNumber : "N/A"}</Td>
                  <Td>{user.country ? user.country : "N/A"}</Td>
                  <Td>{user.jobTitle ? user.jobTitle : "N/A"}</Td>
                  <Td>{user.company ? user.company : "N/A"}</Td>
                  {/* <Td>
                    {user.subscription ? (
                      <CheckIcon color={"green.300"} />
                    ) : (
                      <IconButton
                        variant={"outline"}
                        aria-label={"Renew"}
                        icon={<Icon as={EditIcon} />}
                        color={"green.300"}
                        onClick={() => {
                          renewSubscription(user._id)
                            .then((res) => {
                              toast({
                                title: res.data.message,
                                status: "success",
                                duration: 3000,
                                isClosable: true,
                                position: "top",
                              });
                              setReNewedSubscription(!reNewedSubscription);
                            })
                            .catch((err) => {
                              toast({
                                title: err.response.data.error,
                                status: "error",
                                duration: 3000,
                                isClosable: true,
                              });
                            });
                        }}
                      />
                    )}
                  </Td> */}

                  <Td>
                    <HStack spacing={4}>
                      {!user.isInvited && (
                        <Tooltip
                          label="Send Invitation Email"
                          aria-label="A tooltip"
                        >
                          <IconButton
                            variant={"outline"}
                            aria-label="Edit"
                            icon={<Icon as={CheckIcon} />}
                            onClick={() => {
                              handleInvitationEmailForRegisteredUser(
                                user.email
                              );
                            }}
                          />
                        </Tooltip>
                      )}
                      <IconButton
                        onClick={() => {
                          setUserToBeDeleted(user._id);
                          onOpen();
                        }}
                        variant={"outline"}
                        aria-label="Delete"
                        color={"red.300"}
                        icon={<Icon as={DeleteIcon} />}
                      />
                    </HStack>
                  </Td>
                </Tr>
              ))}
          </Tbody>
        </Table>
        <Flex
          height={5}
          display={"flex"}
          w="full"
          marginTop={5}
          alignItems="center"
          justifyContent="flex-end"
        >
          <Pagination
            defaultCurrent={page}
            onChange={(page) => {
              setPage(page);
              setSearchParams({ page: page });
            }}
            total={count}
            pageSize={perPage}
            paginationProps={{
              display: "flex",
            }}
            colorScheme="blue"
          />
        </Flex>
      </TableContainer>
      {users.length === 0 && (
        <Text fontSize="2xl" textAlign="center" colSpan={5} top={10}>
          No users found
        </Text>
      )}
      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Delete User
            </AlertDialogHeader>

            <AlertDialogBody>
              Are you sure? You can't undo this action afterwards.
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onClose}>
                Cancel
              </Button>
              <Button
                colorScheme="red"
                onClick={() => {
                  delteUser(userToBeDeleted).then((res) => {
                    if (res.data.success === true) {
                      setUsers(
                        users.filter((user) => user._id !== userToBeDeleted)
                      );
                      onClose();
                    }
                  });
                }}
                ml={3}
              >
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </Flex>
  );
}

export default Users;
