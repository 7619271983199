import axios from "axios";
import Cookies from "js-cookie";

const requestInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    "Content-Type": "application/json",

    Accept: "application/json",
    Authorization: `${
      Cookies.get("accessToken") ? Cookies.get("accessToken") : ""
    }`,
  },
});

requestInstance.interceptors.request.use(
  (config) => {
    const token = Cookies.get("accessToken");
    if (token) {
      config.headers.Authorization = token;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// an interceptor to handle the token refresh
requestInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    const originalRequest = error.config;
    const accessToken = Cookies.get("accessToken");
    const refreshToken = Cookies.get("refreshToken");
    const subscriptionToken = Cookies.get("subscriptionToken");
    const tokensExist = accessToken && refreshToken;
    const user = localStorage.getItem("user");
    if (user && !tokensExist) {
      localStorage.removeItem("user");
      window.location.href = "/login";
    }
    // if (!accessToken || !refreshToken) {
    //   localStorage.removeItem("user");
    //   window.location.href = "/login";
    // }
    const message = error.response.data.message;
    if (message) {
      const tokenType = message?.tokenType;
      const tokenExpired = message?.tokenExpired;
      if (tokenType === "access" && tokenExpired) {
        try {
          return axios
            .post(`${process.env.REACT_APP_API_URL}/api/v1/auth/refresh`, {
              refreshToken,
              subscriptionToken,
            })
            .then((res) => {
              if (res.status === 200) {
                Cookies.set("accessToken", res.data.data.accessToken);
                Cookies.set("refreshToken", res.data.data.refreshToken);
                requestInstance.defaults.headers.common[
                  "Authorization"
                ] = `${res.data.data.accessToken}`;
                originalRequest.headers[
                  "Authorization"
                ] = `${res.data.data.accessToken}`;
                return requestInstance(originalRequest);
              }
            })
            .catch((err) => {
              const message = err.response.data.message;
              if (message) {
                const tokenType = message?.tokenType;
                const tokenExpired = message?.tokenExpired;
                if (tokenType === "refresh" && tokenExpired) {
                  Cookies.remove("accessToken");
                  Cookies.remove("refreshToken");
                  localStorage.removeItem("user");
                  window.location.href = "/login";
                }
              }
            });
        } catch (err) {
          Cookies.remove("accessToken");
          Cookies.remove("refreshToken");
          localStorage.removeItem("user");
          window.location.href = "/login";
          return Promise.reject(err);
        }
      }
    }
    return Promise.reject(error);
  }
);

export default requestInstance;
