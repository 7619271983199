import React from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import { authProtectedRoutes, publicRoutes,authProtectedRegisteratonRoutes } from "./routes";
import PrivateResource from "./routes/route";
import Navbar from "./layouts/Navbar";
import Sidebar from "./layouts/Sidebar";
import SimpleLayout from "./layouts/SimpleLayout";

function App() {
  return (
    <React.Fragment>
      <Router>
        <Routes>
          {publicRoutes.map((route, idx) => (
            <Route path={route.path} element={<route.component />} key={idx} />
          ))}
          {authProtectedRoutes.map(
            (route, idx) => (
              (
                <Route
                  path={route.path}
                  element={
                    <PrivateResource
                      component={route.component}
                      layout={Navbar}
                      hasPermission={route.hasPermission}
                    />
                  }
                  key={idx}
                />
              )
            )
          )}
          {
            authProtectedRegisteratonRoutes.map(
              (route, idx) => (
                (
                  <Route
                    path={route.path}
                    element={
                      <PrivateResource
                        component={route.component}
                        layout={SimpleLayout}
                        hasPermission={route.hasPermission}
                      />
                    }
                    key={idx}
                  />
                )
              )
            )
          }
          <Route path="*" element={<h1>Not Found</h1>} />
        </Routes>
      </Router>
    </React.Fragment>
  );
}

export default App;
