import requestInstance from "./requestInstance";

export const getSessions = (page, perPage) => {
  return requestInstance.get(
    `/api/v1/sessions/get?page=${page}&perPage=${perPage}`
  );
};

export const createSession = (sessionName) => {
  return requestInstance.post("/api/v1/sessions/create", { sessionName });
};

export const updateSession = (sessionName, sessionID) => {
  return requestInstance.put(`/api/v1/sessions/update/${sessionID}`, {
    sessionName,
  });
};

export const deleteSession = (sessionID) => {
  return requestInstance.delete(`/api/v1/sessions/delete/${sessionID}`);
};

export const restoreSession = (sessionID) => {
  return requestInstance.put(`/api/v1/sessions/restore/${sessionID}`);
};

export const pinSession = (sessionID) => {
  return requestInstance.put(`/api/v1/sessions/pin/${sessionID}`);
};

export const unpinSession = (sessionID) => {
  return requestInstance.put(`/api/v1/sessions/unpin/${sessionID}`);
};
