import React, { memo } from "react";
import { Box, HStack, Text } from "@chakra-ui/react";
import SessionBarItem from "./SessionBarItem";
import moment from "moment";
import { useSearchParams } from "react-router-dom";
import "../../index.css";
import { useImportantStatsStore } from "../../store/importantStats";
import { useSessionNameStore } from "../../store/sessionName";

const Sessions = ({ sessions, setChats, setScrollToBottom, setSessions }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const currentSessionId = searchParams.get("sessionID");
  const { newSessionCreated } = useImportantStatsStore();
  const { setSessionName } = useSessionNameStore();
  const handleClickOnSession = (sessionId) => {
    // setChats([]);
    setSearchParams({ sessionID: sessionId });
    setScrollToBottom(true);
  };
  return sessions.map((session) => (
    <Box
      key={session._id}
      w="100%"
      h="50px"
      bg={session._id === currentSessionId ? "#3f6fa7" : "#294566"}
      _hover={{
        bg: "#3f6fa7",
      }}
      color={"white"}
      align="center"
      justify="center"
      padding={4}
      borderRadius={8}
      margin={0}
    >
      <SessionBarItem
        setSessions={setSessions}
        session={session}
        handleClick={() => {
          handleClickOnSession(session._id);
          setSessionName(session.name);
        }}
        setChats={setChats}
      />
      <HStack position={"relative"} top={"-10px"} w="100%">
        <Text fontSize={"xx-small"}>
          {moment(session.createdAt).format("D MMMM YYYY")}
        </Text>
      </HStack>
    </Box>
  ));
};

export default memo(Sessions);
