import React from "react";
import { Flex, Button, Input, HStack, Progress, Stack } from "@chakra-ui/react";
import { getNSuggeestedQuestions } from "../api/suggestedQuestion";
import { useCoinStore } from "../store/coin";

const Footer = ({
  deletedChat,
  inputMessage,
  setInputMessage,
  handleSendMessage,
  messagesLength,
}) => {
  const [suggestedQuestions, setSuggestedQuestions] = React.useState([]);

  React.useEffect(() => {
    if (messagesLength === 0) {
      getNSuggeestedQuestions(2)
        .then((res) => {
          setSuggestedQuestions(res.data.data);
        })
        .catch((err) => {
          console.log(err);
        });
      console.log(suggestedQuestions);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messagesLength]);

  return (
    <>
      {!deletedChat && (
        <Stack spacing={0} w="100%">
          <Stack spacing={2} mb={4} direction={{ base: "column", md: "row" }}>
            {suggestedQuestions &&
              !messagesLength &&
              suggestedQuestions.length > 0 &&
              suggestedQuestions.map((question) => (
                <Flex
                  key={question._id}
                  onClick={() => setInputMessage(question.question)}
                  bg={"#294566"}
                  color={"white"}
                  borderRadius={5}
                  padding={2}
                  _hover={{ bg: "#3f6fa7" }}
                >
                  {question.question}
                </Flex>
              ))}
          </Stack>
          <HStack spacing={2} mb={4}>
            <Input
              padding="28px"
              placeholder="Type Something..."
              border="1px solid gray"
              borderRadius={5}
              _focus={{ border: "1px solid gray" }}
              w="100%"
              onKeyPress={(e) => {
                if (e.key === "Enter" && !e.shiftKey) {
                  handleSendMessage();
                } else if (e.shiftKey && e.key === "Enter") {
                  setInputMessage(inputMessage + "\n");
                }
              }}
              value={inputMessage}
              onChange={(e) => setInputMessage(e.target.value)}
            />
            <Button
              padding="28px"
              bg="#41b1ee"
              color="white"
              borderRadius={5}
              ml={2}
              _hover={{
                bg: "white",
                color: "#41b1ee",
                border: "1px solid #41b1ee",
              }}
              onClick={() => {
                handleSendMessage();
              }}
            >
              Send
            </Button>
          </HStack>
        </Stack>
      )}

      {/* <Flex w={"100%"}>
        {deletedChat === false ? (
          <>
            {messagesLength === 0 &&
              suggestedQuestions &&
              suggestedQuestions.length > 0 && (
                <HStack spacing={2} mb={4} position={"absolute"}>
                  {suggestedQuestions.map((question) => (
                    <Flex
                      height={"50px"}
                      fontSize={"14px"}
                      key={question._id}
                      width={"100%"}
                      onClick={() => {
                        setInputMessage(question.question);
                      }}
                      bg={"#294566"}
                      color={"white"}
                      borderRadius={5}
                      padding={2}
                      _hover={{
                        bg: "#3f6fa7",
                      }}
                    >
                      {question.question}
                    </Flex>
                  ))}
                </HStack>
              )}
            <Flex w={"100%"}>
              <Stack spacing={0} w="100%" h={"100%"}>
                <Input
                  padding={"28px"}
                  placeholder="Type Something..."
                  border="1px solid gray"
                  borderRadius={5}
                  _focus={{
                    border: "1px solid gray",
                  }}
                  w={"100%"}
                  onKeyPress={(e) => {
                    if (e.key === "Enter" && !e.shiftKey) {
                      handleSendMessage();
                    }
                    // check if two buttons shift and enter are pressed
                    else if (e.shiftKey && e.key === "Enter") {
                      setInputMessage(inputMessage + "\n");
                    }
                  }}
                  value={inputMessage}
                  onChange={(e) => setInputMessage(e.target.value)}
                />
                {/* {coins === 0 && (
                  <Progress
                    size="sm"
                    value={maxQuota - currentUserUsage}
                    max={maxQuota}
                    color={"#41b1ee"}
                    w={"100%"}
                    borderRadius={5}
                  />
                )} 
              </Stack>

              <Button
                padding={"28px"}
                bg="#41b1ee"
                color="white"
                borderRadius={5}
                ml={2}
                _hover={{
                  bg: "white",
                  color: "#41b1ee",
                  border: "1px solid #41b1ee",
                }}
                // disabled={
                //   inputMessage.trim().length <= 0 ||
                //   maxQuota - currentUserUsage <= 0
                // }
                onClick={() => {
                  handleSendMessage();
                  // setCurrentUserUsage(currentUserUsage + 1);
                }}
              >
                Send
              </Button>
            </Flex>
          </>
        ) : (
          <></>
        )}
      </Flex> */}
    </>
  );
};

export default Footer;
