import React from "react";
import {
  FormControl,
  FormLabel,
  FormErrorMessage,
  FormHelperText,
} from "@chakra-ui/react";
import PhoneInput from "react-phone-number-input";

function FormPhoneNumberField({
  label,
  name,
  placeholder,
  value,
  onChange,
  error,
  touched,
  helperText,
}) {
  return (
    <FormControl id={name} isRequired isInvalid={error}>
      <FormLabel>{label}</FormLabel>
      <PhoneInput
        type="tel"
        style={{
          with: "100%",
        }}
        placeholder={placeholder}
        onChange={onChange}
        value={value}
        className={`chakra-input css-1kp110w`}
      />
      <FormHelperText>{!error && helperText}</FormHelperText>
      <FormErrorMessage>{error && "Invalid Phone Number"}</FormErrorMessage>
    </FormControl>
  );
}

export default FormPhoneNumberField;
