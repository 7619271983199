import React, { memo, useEffect } from "react";
import { getSessions, restoreSession } from "../../api/session";

import {
  Box,
  Button,
  HStack,
  IconButton,
  Stack,
  useToast,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Text,
} from "@chakra-ui/react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { MdOutlineRestoreFromTrash } from "react-icons/md";
import "../../index.css";

import Sessions from "./Sessions";
import moment from "moment";
import { useQuotaStore } from "../../store/quota";
import KatoCoin from "../KatoCoin/KatoCoin";
import { useCoinStore } from "../../store/coin";
import { useImportantStatsStore } from "../../store/importantStats";
import { useSessionNameStore } from "../../store/sessionName";

const SessionBarContent = ({ setChats, setScrollToBottom }) => {
  const [sessions, setSessions] = React.useState([]);
  const [deletedSessions, setDeletedSessions] = React.useState([]);
  const [pinnedSessions, setPinnedSessions] = React.useState([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const currentSessionId = searchParams.get("sessionID");
  const { setCurrentUserUsage, setMaxQuota } = useQuotaStore();
  const { setCoins, setExchangeRate, setNumberOfKatoRequests } = useCoinStore();
  const { setNewSessionCreated, newSessionCreated } = useImportantStatsStore();
  const { setSessionName } = useSessionNameStore();
  const toast = useToast();

  const handleRestoreSession = (session) => {
    restoreSession(session._id)
      .then((res) => {
        setDeletedSessions((s) => s.filter((sess) => sess._id !== session._id));
        toast({
          title: res.data.message,
          status: "success",
          duration: 3000,
          isClosable: true,
          position: "top",
        });
      })
      .catch((err) => {
        toast({
          title: err.response.data.error,
          status: "error",
          duration: 3000,
          isClosable: true,
          position: "top",
        });
      });
  };

  useEffect(() => {
    let time = 0;
    if (newSessionCreated) {
      time = 5000;
    }
    setTimeout(() => {
      getSessions(1, 100)
        .then((res) => {
          setSessions(res.data.data.sessions);
          setSessionName(res.data.data.sessions[0].name);
          setDeletedSessions(res.data.data.deletedSessions);
          setPinnedSessions(res.data.data.pinnedSessions);
          setCurrentUserUsage(res.data.data.numberOfTodayMessages.currentUsage);
          setMaxQuota(res.data.data.numberOfTodayMessages.maxQuota);
          setCoins(res.data.data.userKATOCoins);
          setExchangeRate(res.data.data.numberOfRequestsPerKatoCoin);
          setNumberOfKatoRequests(res.data.data.numberOfKatoRequests);
          setNewSessionCreated(false);
        })
        .catch((err) => {});
    }, time);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sessions.length, deletedSessions.length, newSessionCreated]);

  const navigate = useNavigate();
  function sessionWillBeDeletedIn(session) {
    // calculate how many days left for the session to be deleted permanently
    const sevenDaysAgo = moment().subtract(8, "days");
    const daysLeft = moment(session.deletedAt).diff(sevenDaysAgo, "days");
    return daysLeft;
  }

  function createNewSession() {
    navigate("/");
    setChats([]);
    // createSession("New Chat")
    //   .then((res) => {
    //     setSessions([res.data.data.session, ...sessions]);
    //     setSearchParams((s) => ({
    //       sessionID: res.data.data.session._id,
    //     }));
    //     setChats([]);
    //     setScrollToBottom(true);
    //   })
    //   .catch((err) => {});
  }

  return (
    <Box
      w="100%"
      h="100vh"
      backgroundColor={"#f4f6ff"}
      color={"white"}
      align="center"
      justify="center"
    >
      <Stack spacing={8} w="100%" padding={4} h={"100%"}>
        <br />
        <HStack spacing={8} w="100%" justify="space-between">
          {/* <Heading fontSize="xl" fontWeight="extrabold">
              Chats
            </Heading> */}
        </HStack>
        <KatoCoin />
        <Button
          onClick={createNewSession}
          variant={"outline"}
          bg={"#41b1ee"}
          w={"100%"}
          _hover={{
            border: "1px solid #41b1ee",
            bg: "transparent",
            color: "#41b1ee",
          }}
          color={"white"}
          padding={4}
        >
          Create Chat
        </Button>

        {pinnedSessions.length > 0 && (
          <Stack spacing={3} w="100%" p={3} color={"black"}>
            <Accordion allowToggle color={"black"}>
              <AccordionItem>
                <AccordionButton w="100%" px={0}>
                  <Box
                    w="100%"
                    display={"flex"}
                    justifyContent={"space-between"}
                    paddingBottom={2}
                    // paddingTop={2}
                  >
                    Pinned Chats
                  </Box>
                  <AccordionIcon mx={2} />
                </AccordionButton>
                <AccordionPanel
                  height={pinnedSessions.length <= 3 ? "250px" : "100%"}
                  paddingLeft={0}
                  paddingRight={0}
                >
                  <Stack spacing={3} w="100%" p={0} mb={3}>
                    <Sessions
                      sessions={pinnedSessions}
                      setChats={setChats}
                      setScrollToBottom={setScrollToBottom}
                      setSessions={setSessions}
                    />
                  </Stack>
                </AccordionPanel>
              </AccordionItem>
            </Accordion>
          </Stack>
        )}
        <Stack
          spacing={3}
          w="100%"
          overflowY={"scroll"}
          overflowX={"hidden"}
          h={"100%"}
          p={2}
          m={0}
        >
          {/* {loading && sessions.length === 0 && generateNSkeletons(10)} */}
          <Sessions
            sessions={sessions}
            setChats={setChats}
            setScrollToBottom={setScrollToBottom}
            setSessions={setSessions}
          />
        </Stack>
        <Stack
          spacing={3}
          w="100%"
          p={3}
          overflowX={"hidden"}
          overflowY={"scroll"}
          minHeight={"75px"}
        >
          {deletedSessions.length > 0 && (
            <Accordion allowToggle color={"black"}>
              <AccordionItem>
                <AccordionButton w="100%" px={0}>
                  <Box
                    w="100%"
                    display={"flex"}
                    justifyContent={"space-between"}
                    paddingBottom={2}

                    // paddingTop={2}
                  >
                    <Stack spacing={1} textAlign={"left"}>
                      <Text fontSize={"small"}>Deleted Chats</Text>

                      <Text fontSize={"x-small"}>
                        deleted permanently after 7 days
                      </Text>
                    </Stack>
                  </Box>

                  <AccordionIcon mx={2} />
                </AccordionButton>
                <AccordionPanel padding={"0px"}>
                  {deletedSessions.map((session) => (
                    <Box
                      key={session._id}
                      w="100%"
                      h="50px"
                      bg={
                        session._id === currentSessionId ? "#3f6fa7" : "#294566"
                      }
                      _hover={{
                        bg: "#3f6fa7",
                      }}
                      onClick={() => {
                        // setChats([]);
                        setSearchParams({ sessionID: session._id });
                        setScrollToBottom(true);
                      }}
                      color={"white"}
                      align="center"
                      justify="center"
                      padding={"5px"}
                      paddingLeft={4}
                      borderRadius={8}
                      marginBottom={3}
                    >
                      <HStack spacing={2} w="100%">
                        <Box w="100%" h="30px" display={"flex"}>
                          {session.name}
                        </Box>
                        <IconButton
                          onClick={() => {
                            handleRestoreSession(session);
                          }}
                          icon={<MdOutlineRestoreFromTrash />}
                          bg={"transparent"}
                          _hover={{
                            bg: "transparent",
                          }}
                        />
                      </HStack>
                      <HStack position={"relative"} top={"-10px"}>
                        <Text fontSize={"xx-small"}>
                          {"deleted in " +
                            sessionWillBeDeletedIn(session) +
                            " days"}
                        </Text>
                      </HStack>
                    </Box>
                  ))}
                </AccordionPanel>
              </AccordionItem>
            </Accordion>
          )}
        </Stack>
      </Stack>
    </Box>
  );
};

export default memo(SessionBarContent);
