import React from "react";
import {
  Box,
  Stack,
  Heading,
  Text,
  Button,
  useToast,
  Image,
  useColorModeValue,
  Link,
} from "@chakra-ui/react";
import KatoBlack from "../../assets/images/NavLogo.png";
import { useFormik } from "formik";
import { loginValidationSchema } from "../../validation/validation";
import { login } from "../../api/auth";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import FormTextField from "../../components/Fields/FormTextField";
import FormPasswordField from "../../components/Fields/FormPasswordField";

function Login() {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = React.useState(false);
  const toast = useToast();
  const loginFormik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    onSubmit: (values) => {
      login(values.email, values.password)
        .then((res) => {
          Cookies.set("accessToken", res.data.data.accessToken);
          Cookies.set("refreshToken", res.data.data.refreshToken);
          if (res.data.data.user.role === "user") {
            Cookies.set("subscriptionToken", res.data.data.subscriptionToken);
          }
          localStorage.setItem("user", JSON.stringify(res.data.data.user));
          // toast({
          //   title: "Login Successful",
          //   description: "You have successfully logged in.",
          //   status: "success",
          //   duration: 5000,
          //   isClosable: true,
          //   position: "top",
          //   styleConfig: {
          //     bg: "green.500",
          //     color: "white",
          //     screenTop: "100px",
          //   },
          // });
          navigate("/", { replace: true });
        })
        .catch((err) => {
          toast({
            title: err.response.data.error,
            status: "error",
            duration: 5000,
            isClosable: true,
            position: "top",
            styleConfig: {
              bg: "red.500",
              color: "white",
              screenTop: "100px",
            },
          });
        });
    },
    validationSchema: loginValidationSchema,
  });

  return (
    <Box
      w="100%"
      h={{
        base: "100vh",
        md: "100vh",
        lg: "100vh",
        xl: "100vh",
      }}
      bg={"linear-gradient(150deg, #54a1cc 0%, #8752cc 100%)"}
      py={{
        base: "20px",
        md: "10px",
        lg: "0px",
        xl: "0px",
      }}
    >
      <Stack
        w="100%"
        h="100%"
        align="center"
        justifyContent={"space-around"}
        direction={{ base: "column", md: "row" }}
      >
        <Image
          margin={{
            base: "5px",
            md: "5px",
            lg: "5px",
            xl: "5px",
          }}
          src={KatoBlack}
          height={{
            base: "100px",
            md: "100px",
            lg: "200px",
            xl: "200px",
          }}
        />

        <Stack
          spacing={8}
          mx={"auto"}
          minW={{
            base: "sm",
            md: "lg",
            lg: "2xl",
            xl: "2xl",
          }}
          px={6}
        >
          <Box
            rounded={"lg"}
            bg={useColorModeValue("white", "gray.700")}
            boxShadow={"lg"}
            p={8}
          >
            <Stack spacing={4}>
              <Heading
                color={"gray.800"}
                lineHeight={1.1}
                fontSize={{ base: "2xl", sm: "3xl", md: "4xl" }}
              >
                Login to your account
              </Heading>
            </Stack>
            <Box as={"form"} mt={10}>
              <Stack spacing={4}>
                <FormTextField
                  id="email"
                  name={"email"}
                  label="Email Address"
                  type="email"
                  // placeholder="Enter your email address"
                  onChange={loginFormik.handleChange}
                  value={loginFormik.values.email}
                  error={loginFormik.errors.email}
                  touched={loginFormik.touched.email}
                  helperText={" "}
                />
                {/* <FormControl
                  id="email"
                  isRequired
                  isInvalid={
                    loginFormik.errors.email && loginFormik.touched.email
                  }
                >
                  <FormLabel>Email address</FormLabel>
                  <Input
                    type="email"
                    placeholder="email@example.com"
                    bg={"gray.100"}
                    border={0}
                    color={"gray.500"}
                    _placeholder={{
                      color: "gray.500",
                    }}
                    isInvalid={
                      loginFormik.errors.email && loginFormik.touched.email
                    }
                    onChange={loginFormik.handleChange}
                    value={loginFormik.values.email}
                  />
                  <FormErrorMessage>
                    {loginFormik.errors.email}
                  </FormErrorMessage>
                </FormControl> */}
                <Text
                  align={"right"}
                  color={"blue.400"}
                  fontSize={"sm"}
                  mt={2}
                  mb={2}
                  position={"relative"}
                  top={"40px"}
                  zIndex={1}
                >
                  <Link
                    onClick={() => {
                      navigate("/forget-password");
                    }}
                  >
                    Forgot Password?
                  </Link>
                </Text>
                <FormPasswordField
                  id="password"
                  name={"password"}
                  label="Password"
                  // placeholder="Enter your password"
                  onChange={loginFormik.handleChange}
                  value={loginFormik.values.password}
                  error={loginFormik.errors.password}
                  touched={loginFormik.touched.password}
                  showPassword={showPassword}
                  setShowPassword={setShowPassword}
                  helperText={" "}
                />
                {/* <FormControl
                  id="password"
                  isRequired
                  isInvalid={
                    loginFormik.errors.password && loginFormik.touched.password
                  }
                >
                  <FormLabel>Password</FormLabel>
                  <Input
                    placeholder="password"
                    bg={"gray.100"}
                    border={0}
                    color={"gray.500"}
                    _placeholder={{
                      color: "gray.500",
                    }}
                    onChange={loginFormik.handleChange}
                    isInvalid={
                      loginFormik.errors.password &&
                      loginFormik.touched.password
                    }
                    value={loginFormik.values.password}
                    type="password"
                  />
                  <FormErrorMessage>
                    {loginFormik.errors.password}
                  </FormErrorMessage>
                </FormControl> */}
              </Stack>
              <Button
                fontFamily={"heading"}
                mt={8}
                w={"full"}
                color={"white"}
                bg={"blue.400"}
                onClick={loginFormik.handleSubmit}
                type="submit"
              >
                Submit
              </Button>
              <Stack pt={6}>
                <Text align={"center"}>
                  Don't have an account{" "}
                  <Link
                    color={"blue.400"}
                    onClick={() => {
                      navigate("/register");
                    }}
                  >
                    Create a new account
                  </Link>
                </Text>
              </Stack>
            </Box>
          </Box>
        </Stack>
      </Stack>
    </Box>
  );
}

export default Login;
