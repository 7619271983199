import React, { useEffect, useRef, useMemo } from "react";
import {
  Box,
  Flex,
  HStack,
  IconButton,
  Stack,
  Text,
  VStack,
  useToast,
} from "@chakra-ui/react";
import { BiLike, BiDislike, BiCopy } from "react-icons/bi";
import moment from "moment";
import { dislikeMessageAPI, likeMessageAPI } from "../api/chat";
import Showdown from "showdown";
const buble = require("buble");

export const MarkdownToJSX = ({ md }) => {
  const makeComponent = useMemo(() => {
    if (typeof md !== "string") return null;
    const converter = new Showdown.Converter({
      tables: true,
      simplifiedAutoLink: true,
      strikethrough: true,
      tasklists: true,
      simpleLineBreaks: true,
      openLinksInNewWindow: true,
      emoji: true,
      requireSpaceBeforeHeadingText: true,
      smartIndentationFix: true,
      backslashEscapesHTMLTags: true,
      disableForced4SpacesIndentedSublists: true,
    });
    const html = `<>${converter.makeHtml(md)}</>`;
    const code = buble.transform(html).code;
    // eslint-disable-next-line no-new-func
    const makeComponent = Function("React", "return " + code);

    return makeComponent;
  }, [md]);

  return makeComponent(React);
};

const Messages = ({
  messages,
  response,
  isStreaming,
  scrollToBottom,
  setMessages,
  setScrollToBottom,
  loading,
  targetRef,
}) => {
  const AlwaysScrollToBottom = () => {
    const elementRef = useRef();
    useEffect(() => elementRef.current.scrollIntoView());
    return (
      <div
        ref={elementRef}
        style={{
          float: "left",
          clear: "both",
        }}
      />
    );
  };

  // const [scrollingUp, setScrollingUp] = React.useState(false);
  // const scrollRef = React.useRef(null);

  // detect scrolling up and update the state accordingly

  // useEffect(() => {
  //   const scrollDiv = scrollRef.current;
  //   if (scrollDiv) {
  //     scrollDiv.addEventListener("scroll", (e) => {
  //       if (e.target.scrollTop === 0) {
  //         setScrollingUp(true);
  //         // scrolldown to avoid weird behavior

  //         scrollDiv.scrollTo({
  //           down: scrollRef.current.scrollHeight -3,
  //           behavior: "smooth",
  //         });

  //       } else {
  //         setScrollingUp(false);
  //       }
  //     });
  //   }
  //   return () => {
  //     if (scrollDiv) {
  //       scrollDiv.removeEventListener("scroll", () => {});
  //     }
  //   };
  // }, [scrollRef]);
  const toast = useToast();
  const likeMessage = (id, item) => {
    item.liked = true;
    item.disliked = false;
    updateMessagesState(id, item);
    setScrollToBottom(false);
    likeMessageAPI(id)
      .then((res) => {
        toast({
          title: res.data.message,
          status: "success",
          duration: 3000,
          isClosable: true,
          position: "top",
        });
      })
      .catch((err) => {
        toast({
          title: err.response.data.error,
          status: "error",
          duration: 3000,
          isClosable: true,
          position: "top",
        });
      });
  };

  const dislikeMessage = (id, item) => {
    item.liked = false;
    item.disliked = true;
    updateMessagesState(id, item);
    setScrollToBottom(false);
    dislikeMessageAPI(id)
      .then((res) => {
        toast({
          title: res.data.message,
          status: "success",
          duration: 3000,
          isClosable: true,
          position: "top",
        });
      })
      .catch((err) => {
        toast({
          title: err.response.data.error,
          status: "error",
          duration: 3000,
          isClosable: true,
          position: "top",
        });
      });
  };

  const updateMessagesState = (itemId, updatedMessage) => {
    //find that specific message and update it
    setMessages(
      messages.map((item) =>
        item._id === itemId ? { ...item, ...updatedMessage } : item
      )
    );
  };

  const converter = new Showdown.Converter();
  converter.setFlavor("github");
  converter.setOption("tables", true);
  converter.setOption("emoji", true);
  converter.setOption("simpleLineBreaks", true);
  converter.setOption("smartIndentationFix", true);
  converter.setOption("requireSpaceBeforeHeadingText", true);
  converter.setOption("smoothLivePreview", true);
  converter.setOption("disableForced4SpacesIndentedSublists", true);
  converter.setOption("strikethrough", true);
  converter.setOption("tasklists", true);
  converter.setOption("simpleLineBreaks", true);
  converter.setOption("openLinksInNewWindow", true);
  converter.setOption("backslashEscapesHTMLTags", true);

  const processMessage = (message) => {
    return converter.makeHtml(message);
  };

  return (
    <Flex
      id="scrollableDiv"
      w="100%"
      h="80%"
      overflowY="scroll"
      overflowX={"hidden"}
      flexDirection="column"
      p="3"
      overflow={"auto"}
      marginBottom={5}
    >
      {messages.length === 0 && !loading && (
        <Flex
          w="100%"
          justify="center"
          position={"relative"}
          top={"50%"}
          transform={"translateY(-50%)"}
        >
          <Text color="gray.500">No messages yet</Text>
        </Flex>
      )}
      <Box ref={targetRef}>
        {/* {loading && (
          <Stack spacing={5} padding={"5px"}>
            <Skeleton
              h={"50px"}
              startColor="brand.700"
              endColor="brand.400"
              colorScheme="brand"
              my="1"
              p="3"
              marginLeft={20}
              style={{ borderRadius: "20px 0px 20px 20px" }}
              animation={"0.2s linear infinite alternate animation-14pkoxc"}
              opacity={"0.8"}
            />
            <Skeleton
              h={"300px"}
              colorScheme="brand"
              my="1"
              p="3"
              overflowX="auto"
              marginRight={20}
              style={{ borderRadius: "0px 20px 20px 20px" }}
            />
          </Stack>
        )} */}
        {messages.length > 0 &&
          messages.map((item, index) => {
            return (
              item && (
                <Box key={index}>
                  {item.from !== "computer" ? (
                    <>
                      <Flex key={index} w="100%" justify="flex-end">
                        <Flex
                          bg="#294566"
                          color="white"
                          my="1"
                          p="3"
                          marginLeft={20}
                          style={{ borderRadius: "20px 0px 20px 20px" }}
                        >
                          <div
                            dangerouslySetInnerHTML={{
                              __html: processMessage(item.message),
                            }}
                          />
                        </Flex>
                      </Flex>
                      <VStack
                        spacing={2}
                        alignItems={"flex-end"}
                        padding={"5px"}
                      >
                        <Text fontSize={"x-small"}>
                          {moment(item.createdAt).fromNow()}
                        </Text>
                      </VStack>
                    </>
                  ) : (
                    <>
                      <Flex key={index} w="100%">
                        <Flex
                          bg="gray.100"
                          color="black"
                          p="3"
                          px={"10"}
                          overflowX="auto"
                          marginRight={20}
                          style={{ borderRadius: "0px 20px 20px 20px" }}
                        >
                          <Stack>
                            <div
                              dangerouslySetInnerHTML={{
                                __html: processMessage(item.message),
                              }}
                            />

                            <HStack spacing={2}>
                              {item.liked ? (
                                <IconButton
                                  icon={<BiLike />}
                                  color="green.300"
                                  disabled={true}
                                />
                              ) : item.disliked ? null : (
                                <IconButton
                                  icon={<BiLike />}
                                  onClick={() => {
                                    likeMessage(item._id, item);
                                  }}
                                />
                              )}
                              {item.disliked ? (
                                <IconButton
                                  icon={<BiDislike />}
                                  color="red.300"
                                  disabled={true}
                                />
                              ) : item.liked ? null : (
                                <IconButton
                                  icon={<BiDislike />}
                                  onClick={() => {
                                    dislikeMessage(item._id, item);
                                  }}
                                />
                              )}

                              <IconButton
                                icon={<BiCopy />}
                                onClick={() => {
                                  navigator.clipboard.writeText(item.message);
                                  toast({
                                    title: "Message copied to clipboard",
                                    status: "success",
                                    duration: 3000,
                                    isClosable: true,
                                    position: "top",
                                  });
                                }}
                              />
                            </HStack>
                          </Stack>
                        </Flex>
                      </Flex>
                      <VStack
                        spacing={2}
                        alignItems={"flex-start"}
                        padding={"5px"}
                      >
                        <Text fontSize={"x-small"}>
                          {moment(item.createdAt).fromNow()}
                        </Text>

                        {/* <HStack spacing={2}>
                  <IconButton
                    icon={<SearchIcon />}
                    style={{
                      borderRadius: "50px",
                    }}
                  />
                </HStack> */}
                      </VStack>
                    </>
                  )}
                </Box>
              )
            );
          })}
      </Box>
      {isStreaming && (
        <Flex w="100%">
          <Flex
            bg="gray.100"
            color="black"
            my="1"
            p="3"
            px={"10"}
            overflowX="auto"
            marginRight={20}
            style={{ borderRadius: "0px 20px 20px 20px" }}
          >
            <div
              dangerouslySetInnerHTML={{ __html: processMessage(response) }}
            />
          </Flex>
        </Flex>
      )}
      {scrollToBottom && <AlwaysScrollToBottom />}
    </Flex>
  );
};

export default Messages;
