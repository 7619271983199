import React, { memo, useEffect } from "react";
import {
  deleteSession,
  pinSession,
  unpinSession,
  updateSession,
} from "../../api/session";

import {
  Box,
  Button,
  ButtonGroup,
  Editable,
  EditableInput,
  EditablePreview,
  Flex,
  FormControl,
  HStack,
  IconButton,
  Input,
  useEditableControls,
  useToast,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import "../../index.css";
import { HiOutlineDotsVertical } from "react-icons/hi";
const { CheckIcon, CloseIcon, EditIcon } = require("@chakra-ui/icons");

const SessionBarItem = ({ session, handleClick, setSessions, setChats }) => {
  const [sessionName, setSessionName] = React.useState(session.name);
  const [sessionNameError, setSessionNameError] = React.useState(false);
  const navigate = useNavigate();
  const toast = useToast();

  const handleSessionNameChange = (e) => {
    setSessionName(e);
  };

  const handleUpdateSessionName = () => {
    updateSession(sessionName, session._id)
      .then((res) => {
        setSessionName(res.data.data.session.name);
        toast({
          title: res.data.message,
          status: "success",
          duration: 3000,
          isClosable: true,
          position: "top",
        });
      })
      .catch((err) => {
        toast({
          title: err.response.data.error,
          status: "error",
          duration: 3000,
          isClosable: true,
          position: "top",
        });
      });
  };

  const handlePinSession = () => {
    pinSession(session._id)
      .then((res) => {
        setSessions((s) => s.filter((sess) => sess._id !== session._id));
      })
      .catch((err) => {
        toast({
          title: err.response.data.error,
          status: "error",
          duration: 3000,
          isClosable: true,
          position: "top",
        });
      });
  };

  const handleUnpinSession = () => {
    unpinSession(session._id)
      .then((res) => {
        setSessions((s) => {
          return [session, ...s];
        });
      })
      .catch((err) => {
        toast({
          title: err.response.data.error,
          status: "error",
          duration: 3000,
          isClosable: true,
          position: "top",
        });
      });
  };

  const handleDeleteSession = () => {
    deleteSession(session._id)
      .then((res) => {
        toast({
          title: res.data.message,
          status: "success",
          duration: 3000,
          isClosable: true,
          position: "top",
        });
        setSessions((s) => s.filter((sess) => sess._id !== session._id));
        setChats([]);
        navigate("/");
      })
      .catch((err) => {
        toast({
          title: err.response.data.error,
          status: "error",
          duration: 3000,
          isClosable: true,
          position: "top",
        });
      });
  };

  useEffect(() => {
    if (sessionName.length < 3) {
      setSessionNameError(true);
    } else {
      setSessionNameError(false);
    }
  }, [sessionName, sessionNameError]);
  function EditableControls({ error, handleSessionNameChange }) {
    const {
      isEditing,
      getSubmitButtonProps,
      getCancelButtonProps,
      getEditButtonProps,
    } = useEditableControls();

    return isEditing ? (
      <ButtonGroup justifyContent="center" size="sm">
        <IconButton
          icon={<CheckIcon />}
          {...getSubmitButtonProps()}
          bg={"transparent"}
          _hover={{ bg: "transparent" }}
          onClick={() => {
            if (!error) {
              handleUpdateSessionName();
              getSubmitButtonProps().onClick();
            }
          }}
        />
        <IconButton
          icon={<CloseIcon />}
          {...getCancelButtonProps()}
          bg={"transparent"}
          _hover={{ bg: "transparent" }}
        />

        {/* <IconButton
              icon={<DeleteIcon />}
              {...getCancelButtonProps()}
              bg={"transparent"}
              _hover={{ bg: "transparent" }}
              onClick={() => {
                onToggle();
              }}
            ></IconButton> */}
      </ButtonGroup>
    ) : (
      <Flex justifyContent="center">
        <IconButton
          size="sm"
          icon={<EditIcon />}
          {...getEditButtonProps()}
          bg={"transparent"}
          _hover={{ bg: "transparent" }}
        />
      </Flex>
    );
  }

  return (
    <Editable
      textAlign="center"
      defaultValue={sessionName}
      onChange={handleSessionNameChange}
      fontSize="1xl"
      onClick={handleClick}
      display={"flex"}
      justifyContent={"space-between"}
      h={{
        base: "30px",
        md: "30px",
        lg: "30px",
        xl: "30px",
      }}
      position={"relative"}
      top={"-7px"}
      margin={0}
      padding={0}
    >
      {/* Here is the custom input */}
      <HStack
        spacing={1}
        w="100%"
        justifyContent={"flex-start"}
        margin={0}
        padding={0}
      >
        {/* make the text limited to one line and the rest to dots  */}
        <EditablePreview
          width={"600%"}
          display={"flex"}
          padding={0}
          margin={0}
          textOverflow={"ellipsis"}
          overflow={"hidden"}
          whiteSpace={"nowrap"}
          h={{
            base: "30px",
            md: "30px",
            lg: "30px",
            xl: "30px",
          }}
        />

        <Box w="100%" padding={0}>
          <FormControl
            width={"100%"}
            id={`name-${session._id}`}
            isInvalid={sessionNameError}
            h={{
              base: "30px",
              md: "30px",
              lg: "30px",
              xl: "30px",
            }}
          >
            <Input
              h={{
                base: "30px",
                md: "30px",
                lg: "30px",
                xl: "30px",
              }}
              errorBorderColor="red"
              as={EditableInput}
              style={{ borderWidth: "0px", borderColor: "transparent" }}
              _focus={{ borderWidth: "0px" }}
              _focusWithin={{ borderWidth: "0px" }}
              isInvalid={sessionNameError}
              _invalid={{ borderWidth: "1px", borderColor: "red" }}
              width={"100%"}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  if (!sessionNameError) {
                    handleUpdateSessionName();
                  }
                }
              }}
            />
          </FormControl>
        </Box>
        {/* <Popover position="absolute"> */}
        <EditableControls error={sessionNameError} />
        {/* <PopoverContent color="black" padding={2}>
            <PopoverArrow />
            <PopoverCloseButton />
            <PopoverHeader color={"black"}>Confirmation!</PopoverHeader>
            <PopoverBody color={"black"} margin={"10px"}>
              Are you sure you want to delete?
            </PopoverBody> */}
        {/* <HStack spacing={2} justifyContent={"space-around"}>
          <Button
            bgColor={"red.500"}
            color={"white"}
            _hover={{
              bgColor: "red.300",
            }}
            onClick={() => {}}
          >
            delete
          </Button>
        </HStack> */}
        {/* </PopoverContent> */}
        <Menu>
          <MenuButton
            bg={"transparent"}
            _hover={{ bg: "transparent" }}
            _active={{ bg: "transparent" }}
            as={Button}
            rightIcon={<HiOutlineDotsVertical />}
            onClick={(e) => {
              e.stopPropagation();
            }}
          ></MenuButton>
          <MenuList color="black" zIndex={10000}>
            {session.pinned ? (
              <MenuItem
                onClick={() => {
                  handleUnpinSession();
                }}
              >
                Unpin
              </MenuItem>
            ) : (
              <MenuItem
                onClick={() => {
                  handlePinSession();
                }}
              >
                Pin
              </MenuItem>
            )}
            <MenuItem
              onClick={() => {
                handleDeleteSession();
              }}
            >
              Delete
            </MenuItem>
          </MenuList>
        </Menu>
        {/* </Popover> */}
      </HStack>
    </Editable>
  );
};

export default memo(SessionBarItem);
