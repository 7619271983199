import React, { useEffect } from "react";
import { getResources } from "../../api/resources";
import {
  Box,
  HStack,
  Table,
  TableContainer,
  Tbody,
  Text,
  Th,
  Thead,
  Tr,
  FormControl,
  Input,
  IconButton,
  Badge,
  Tag,
  Tooltip,
} from "@chakra-ui/react";
import { SearchIcon } from "@chakra-ui/icons";
import Pagination from "@choc-ui/paginator";
import { useSearchParams } from "react-router-dom";

function Resources() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [resources, setResources] = React.useState([]);
  const [searchQuery, setSearchQuery] = React.useState(
    searchParams.get("searchQuery") || ""
  );
  const [page, setPage] = React.useState(searchParams.get("page") || 1);
  const [count, setCount] = React.useState(0);

  useEffect(() => {
    getResources(searchQuery, page, 15).then((res) => {
      setResources(res.data.data.resources);
      setCount(res.data.data.count);
    });
  }, [searchParams, page]);

  return (
    <Box p={10}>
      <br />
      <br />
      <HStack justifyContent={"space-between"}>
        <Text fontSize="3xl">Resources</Text>
      </HStack>
      <TableContainer
        p={5}
        mt={10}
        borderWidth={1}
        borderRadius={8}
        width={"100%"}
        height={"860px"}
      >
        <HStack spacing={4} justifyContent={"space-between"} width={"100%"}>
          <FormControl>
            <Input
              placeHolder="Search"
              value={searchQuery}
              onChange={(e) => {
                setSearchQuery(e.target.value);
              }}
            />
          </FormControl>
          <IconButton
            icon={<SearchIcon />}
            color={"white"}
            bg={"black"}
            _hover={{
              bg: "gray.700",
            }}
            onClick={() => {
              setPage(1);
              setSearchParams({ page: page, searchQuery: searchQuery });
            }}
          />
        </HStack>
        <Table colorScheme="gray" size="md">
          <Thead>
            <Tr>
              <Th>no.</Th>
              <Th>Resource Name</Th>
              <Th>Status</Th>
            </Tr>
          </Thead>
          <Tbody>
            {resources.length > 0 ? (
              resources.map((resource, idx) => (
                <React.Fragment key={resource._id}>
                  <Tr key={resource._id}>
                    <Th>{(page - 1) * 15 + (idx + 1)}</Th>
                    <Th>
                      <Tooltip hasArrow label={resource.name} bg={"brand.500"}>
                        {resource.name.length > 100
                          ? resource.name.slice(0, 100) + "..."
                          : resource.name}
                      </Tooltip>
                      {resource.url ? " - " + resource.url : null}
                    </Th>
                    <Th>
                      {resource.numCharacters !== 0 ? (
                        <Tag size="sm" colorScheme="green">
                          success
                        </Tag>
                      ) : (
                        <Tag size="sm" colorScheme="red">
                          Failure
                        </Tag>
                      )}
                    </Th>
                  </Tr>
                </React.Fragment>
              ))
            ) : (
              <Tr>
                <Th>No Resources</Th>
              </Tr>
            )}
          </Tbody>
        </Table>
        <Box
          height={5}
          display={"flex"}
          w="full"
          marginTop={5}
          alignItems="center"
          justifyContent="flex-end"
          position={"absolute"}
          bottom={85}
          right={12}
        >
          <Pagination
            defaultCurrent={page}
            onChange={(page) => {
              setPage(page);
              setSearchParams({ page: page, searchQuery: searchQuery });
            }}
            total={count}
            pageSize={15}
            paginationProps={{
              display: "flex",
            }}
          />
        </Box>
      </TableContainer>
    </Box>
  );
}

export default Resources;
