import { Box, Stack, chakra } from "@chakra-ui/react";
import React from "react";

function AboutUs() {
  return (
    <Box minH="100vh" p={10}>
      <Stack spacing={8} mx={"auto"} maxW={"3xl"} py={12} px={6}>
        <Stack>
          <chakra.p
            textAlign={{
              base: "left",
              md: "justify",
            }}
          >
            The Founder’s words:
          </chakra.p>
          <chakra.p
            textAlign={{
              base: "left",
              md: "justify",
            }}
          >
            KATO is the Legal AI revolution. It is the literal use of the power
            of artificial intelligence to provide comprehensive and accessible
            legal information. Its advanced technology can sort through vast
            amounts of legal data, pulling relevant information in response to a
            user's question. KATO is an AI paralegal, a repository of legal
            knowledge that is accessible to both legal professionals and
            nonlegal individuals who are interested in getting some legal
            insight or knowledge.
          </chakra.p>
        </Stack>
        <Stack>
          <chakra.h2 fontSize={"3xl"} fontWeight={"bold"} textAlign={"left"}>
            The Evolution of Legal Technology
          </chakra.h2>
          <chakra.h3 fontSize={"2xl"} fontWeight={"bold"} textAlign={"left"}>
            From Basic Software to AI
          </chakra.h3>
          <chakra.p
            textAlign={{
              base: "left",
              md: "justify",
            }}
          >
            As a lawyer who has dedicated more than 20 years of my life to the
            study and practice of law, that’s half my life, I have been both a
            witness and participant in a remarkable transformation.
          </chakra.p>
          <chakra.p
            textAlign={{
              base: "left",
              md: "justify",
            }}
          >
            Having been born into a family of lawyers and essentially raised in
            a law firm, I’ve observed the journey from the early days of legal
            technology to the rise of AI-driven legal tools.
          </chakra.p>

          {/* <Stack> */}
          {/* <chakra.h3 fontSize={"2xl"} fontWeight={"bold"} textAlign={"left"}>
            The Dawn of Legal Tech
          </chakra.h3> */}
          <chakra.p
            textAlign={{
              base: "left",
              md: "justify",
            }}
          >
            Back in the 1980s, the legal world saw the start of technology that
            revolutionized our lives as well as the legal profession. The first
            legal tech applications were simplistic software solutions that
            aimed to streamline administrative tasks, a marked contrast to the
            traditional method of manually keeping track of billable hours,
            cases, and client information. They were designed to make legal work
            more efficient, saving lawyers time and effort on tasks that, while
            necessary, did little to further their actual legal work.
          </chakra.p>
          <chakra.p
            textAlign={{
              base: "left",
              md: "justify",
            }}
          >
            This move towards digitization marked a profound shift in the legal
            industry. Before this, technology in law firms was sparse at best,
            and non-existent at worst. The arrival of these legal software
            solutions paved the way for an industry that would become
            increasingly intertwined with technology over the following decades.
          </chakra.p>
          {/* </Stack> */}

          {/* <Stack> */}
          {/* <chakra.h3 fontSize={"2xl"} fontWeight={"bold"} textAlign={"left"}>
            The Information Revolution
          </chakra.h3> */}
          <chakra.p
            textAlign={{
              base: "left",
              md: "justify",
            }}
          >
            With the introduction of the <strong> internet</strong> in the 90s,
            a new era. The information revolution brought with it a shift in the
            way we accessed and shared information, including legal data.
          </chakra.p>
          <chakra.p
            textAlign={{
              base: "left",
              md: "justify",
            }}
          >
            The internet became a vast repository of legal information. No
            longer did one have to physically go to a library full of legal
            resources and pore over volumes of legal books and relevant
            literature. Instead, court decisions, statutes, and legal articles
            were just a few clicks away. This vastly increased the speed of
            legal research and democratized access to legal information.
          </chakra.p>
        </Stack>
        <Stack>
          <chakra.h3 fontSize={"2xl"} fontWeight={"bold"} textAlign={"left"}>
            Social Media: The New Frontier for Lawyers
          </chakra.h3>
          <chakra.p
            textAlign={{
              base: "left",
              md: "justify",
            }}
          >
            The next transformative wave came with the advent of social media
            platforms in the early 2000s. Lawyers, once perceived as
            inaccessible professionals in ivory towers, were suddenly thrust
            into the digital arena. Social media platforms like LinkedIn,
            Facebook, WhatsApp, Twitter, and even TikTok gave lawyers a new
            means to reach out to potential clients, showcase their expertise,
            and build their professional network.
          </chakra.p>
          <chakra.p
            textAlign={{
              base: "left",
              md: "justify",
            }}
          >
            These platforms also provided a space for discourse and
            knowledge-sharing among professionals. Suddenly, the legal community
            was not bound by geographical limitations; a lawyer could discuss a
            legal issue with peers from across the globe, fostering a worldwide
            legal community.
          </chakra.p>
        </Stack>
        <Stack>
          <chakra.h3 fontSize={"2xl"} fontWeight={"bold"} textAlign={"left"}>
            Law Firms Embrace Technology
          </chakra.h3>
          <chakra.p
            textAlign={{
              base: "left",
              md: "justify",
            }}
          >
            As the digital landscape continued to evolve, law firms realized the
            potential benefits of leveraging technology in their services. More
            than just administrative tasks, technology started playing a crucial
            role in client management and service delivery.
          </chakra.p>
          <chakra.p
            textAlign={{
              base: "left",
              md: "justify",
            }}
          >
            Many law firms began offering online consultations, leveraging
            email, and eventually video conferencing, to communicate with
            clients. This made legal services more accessible, convenient, and
            efficient, benefiting both lawyers and clients.
          </chakra.p>
          <chakra.p
            textAlign={{
              base: "left",
              md: "justify",
            }}
          >
            We have an evident example that forced all professional service
            providers including lawyers to immediately switch to the Internet
            and to remotely work during the 2020 pandemic. We mastered online
            communication, documents, and knowledge sharing as well as the
            actual execution of work up to negotiating critical and sensitive
            8-digit transactions.
          </chakra.p>
        </Stack>
        <Stack>
          <chakra.h3 fontSize={"2xl"} fontWeight={"bold"} textAlign={"left"}>
            The Rise of Online Courts{" "}
          </chakra.h3>
          <chakra.p
            textAlign={{
              base: "left",
              md: "justify",
            }}
          >
            The judiciary was not far behind in embracing technology. To
            expedite the judicial process and make justice more accessible,
            courts around the world began to experiment with technology.
          </chakra.p>
          <chakra.p
            textAlign={{
              base: "left",
              md: "justify",
            }}
          >
            From electronic filing systems to virtual hearings, the courtrooms'
            very fabric was changing. The Covid-19 pandemic expedited this
            shift, with social distancing measures necessitating remote
            hearings. Courts went from being institutions steeped in centuries
            of tradition to pioneers of digital innovation.
          </chakra.p>
        </Stack>
        <Stack>
          <chakra.h3 fontSize={"2xl"} fontWeight={"bold"} textAlign={"left"}>
            The AI Revolution{" "}
          </chakra.h3>
          <chakra.p
            textAlign={{
              base: "left",
              md: "justify",
            }}
          >
            This brings us to the present and the dawn of the AI revolution in
            legal technology. AI is poised to transform the legal industry in
            ways we are only beginning to understand.
          </chakra.p>
          <chakra.p
            textAlign={{
              base: "left",
              md: "justify",
            }}
          >
            Ai Legal Tools will be taking legal tech to the next level. You will
            soon experience solutions that can understand and answer complex
            legal queries, review and analyze legal documents, and simplify
            legal language for everyday understanding, AI is changing the way we
            interact with the law.
          </chakra.p>
          <chakra.p
            textAlign={{
              base: "left",
              md: "justify",
            }}
          >
            We will soon harness the power of artificial intelligence.
            Comprehensive and accessible legal knowledge will be available. The
            AI’s advanced technology can sort through vast amounts of legal
            data, pulling relevant information in response to a user's query.
          </chakra.p>
          <chakra.p
            textAlign={{
              base: "left",
              md: "justify",
            }}
          >
            The advent of AI in legal tech is making legal advice more
            democratic and accessible. It will be transforming the way legal
            professionals conduct research and interact with clients. For
            individuals, AI-driven tools can offer insights and guidance on
            matters of law, promoting better-informed decisions.
          </chakra.p>
        </Stack>
        <Stack>
          <chakra.h3
            fontSize={"2xl"}
            fontWeight={"bold"}
            align={"center"}
            textAlign={"left"}
          >
            The Promise of AI{" "}
          </chakra.h3>
          <chakra.p
            textAlign={{
              base: "left",
              md: "justify",
            }}
          >
            Artificial intelligence in legal tech holds great promise. As AI
            technology evolves, we can expect these tools to become more
            sophisticated and accurate. They will be able to handle more complex
            tasks and offer more personalized advice. We may even see AI being
            used in the courtroom, aiding judges in decision-making or assisting
            lawyers in case preparation.
          </chakra.p>
          <chakra.p
            textAlign={{
              base: "left",
              md: "justify",
            }}
          >
            However, the rise of AI in legal tech also raises questions. How do
            we ensure the accuracy of AI-generated advice? How do we balance the
            use of AI with the need for human judgment and interpretation in
            legal matters? And most importantly, how do we protect privacy and
            ensure the ethical use of AI?
          </chakra.p>
          <chakra.p style={{ textAlign: "left" }}>
            The answer is: The AI will figure it out.
          </chakra.p>

          {/* <Stack> */}
          {/* <chakra.h3 fontSize={"2xl"} fontWeight={"bold"} textAlign={"left"}>
            Final Thoughts{" "}
          </chakra.h3> */}
          <chakra.p
            textAlign={{
              base: "left",
              md: "justify",
            }}
          >
            Reflecting on the evolution of legal technology, it's clear that we
            are in a remarkable period of innovation. From the rise of legal
            software in the 80s to the internet and social media to AI-driven
            tools, the legal industry has come a long way.
          </chakra.p>
          <chakra.p
            textAlign={{
              base: "left",
              md: "justify",
            }}
          >
            As we look to the future, we should remember the purpose of
            technology in law - to improve the practice of law and the delivery
            of legal services. Technology is not meant to replace lawyers but to
            enhance their abilities and make their work more efficient. It's not
            intended to make legal advice impersonal but to make it more
            accessible.
          </chakra.p>
          <chakra.p
            textAlign={{
              base: "left",
              md: "justify",
            }}
          >
            In the end, the measure of successful legal tech is not in its
            sophistication but in its ability to make a meaningful difference in
            people's lives. In this regard, AI is hope, a tool that is
            democratizing legal data, making it accessible to all, not just a
            privileged few. As we continue on this journey, I encourage
            everybody to embrace this technology. Let us build a future where
            everyone has access to legal advice, a future where law is not a
            mystery but a tool for empowerment.
          </chakra.p>
          <chakra.p style={{ textAlign: "left" }}>
            This is the promise of AI and legal tech. As a lawyer, a tech
            enthusiast, and an individual who's grown up in the era of
            technological transformation, I believe we are witnessing an
            exciting new chapter in the legal industry. The future of legal tech
            holds boundless possibilities.
          </chakra.p>
          <Stack align={"end"}>
            <Stack align={"center"}>
              <chakra.p style={{ textAlign: "center" }}>
                Ahmed Elnaggar
              </chakra.p>
              <chakra.p style={{ textAlign: "center" }}>
                Founder – KATO the AI Paralegal
              </chakra.p>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </Box>
  );
}

export default AboutUs;
