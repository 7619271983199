import {
  Flex,
  Stack,
  Heading,
  Button,
  useToast,
  Image,
  useColorModeValue,
} from "@chakra-ui/react";
import * as Yup from "yup";
import KatoBlack from "../../assets/images/NavLogo.png";
import { useFormik } from "formik";
import { useNavigate, useSearchParams } from "react-router-dom";
import FormPasswordField from "../../components/Fields/FormPasswordField";
import { useState } from "react";
import { renewPassword } from "../../api/auth";

function NewPassword() {
  const toast = useToast();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const formik = useFormik({
    initialValues: {
      password: "",
      confirmPassword: "",
    },
    onSubmit: (values) => {
      console.log(searchParams.get("id"));
      renewPassword(values.password, searchParams.get("id"))
        .then((res) => {
          toast({
            title: res.data.message,
            description: "You can now login with your new password",
            status: "success",
            duration: 10000,
            isClosable: true,
            position: "top",
          });
          navigate("/login");
        })
        .catch((err) => {
          toast({
            title: err.response.data.error,
            status: "error",
            duration: 10000,
            isClosable: true,
            position: "top",
          });
        });
    },
    validationSchema: Yup.object({
      password: Yup.string()
        .required("Required")
        .min(8, "Too Short!")
        .matches(/(?=.*[0-9])/, "Password must contain a number.")
        .matches(/(?=.*[A-Z])/, "Password must contain an uppercase letter.")
        .matches(/(?=.*[a-z])/, "Password must contain a lowercase letter.")
        .matches(
          /(?=.*[!@#$%^&*])/,
          "Password must contain a special character."
        ),
      confirmPassword: Yup.string()
        .oneOf([Yup.ref("password"), null], "Passwords must match")
        .required("Password is required"),
    }),
  });
  return (
    <Flex
      w="100%"
      minH={{
        base: "100vh",
        md: "100vh",
        lg: "100vh",
        xl: "100vh",
      }}
      bg={"linear-gradient(150deg, #54a1cc 0%, #8752cc 100%)"}
      py={{
        base: "20px",
        md: "20px",
        lg: "20px",
        xl: "20px",
      }}
      alignItems={"center"}
    >
      <Stack
        w="100%"
        h="100%"
        align="center"
        justifyContent={"space-around"}
        direction={{ base: "column", md: "row" }}
      >
        <Image
          margin={{
            base: "5px",
            md: "5px",
            lg: "5px",
            xl: "5px",
          }}
          src={KatoBlack}
          height={{
            base: "100px",
            md: "100px",
            lg: "200px",
            xl: "200px",
          }}
        />

        <Stack
          spacing={4}
          w={"full"}
          maxW={"md"}
          bg={useColorModeValue("white", "gray.700")}
          rounded={"xl"}
          boxShadow={"lg"}
          p={6}
          my={10}
        >
          <Stack spacing={4}>
            <Heading
              color={"gray.800"}
              lineHeight={1.1}
              fontSize={{ base: "2xl", sm: "3xl", md: "4xl" }}
            >
              Forget Password
            </Heading>
          </Stack>
          <FormPasswordField
            name="password"
            placeholder="Password"
            label="Password"
            type="password"
            error={formik.errors.password}
            value={formik.values.password}
            touched={formik.touched.password}
            onChange={formik.handleChange}
            setShowPassword={setShowPassword}
            showPassword={showPassword}
          />
          <FormPasswordField
            placeholder="Confirm Password"
            name="confirmPassword"
            label="Confirm Password"
            type="password"
            error={formik.errors.confirmPassword}
            value={formik.values.confirmPassword}
            touched={formik.touched.confirmPassword}
            onChange={formik.handleChange}
            setShowPassword={setShowConfirmPassword}
            showPassword={showConfirmPassword}
          />

          <Button
            fontFamily={"heading"}
            mt={8}
            w={"full"}
            color={"white"}
            bg={"blue.400"}
            type="submit"
            onClick={formik.handleSubmit}
          >
            Submit
          </Button>
        </Stack>
      </Stack>
    </Flex>
  );
}

export default NewPassword;
