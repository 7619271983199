import requestInstance from "./requestInstance";

export const resendEmailVerification = () => {
  return requestInstance.post("/api/v1/verification/resend-verification-email");
};

export const verifyEmail = (verificationCode) => {
  return requestInstance.post("/api/v1/verification/verify-email", {
    verificationCode,
  });
};

export const sendInvitationEmail = (email) => {
  return requestInstance.post("/api/v1/verification/send-invitation", {
    email,
  });
};

export const sendAcceptanceEmail = (email) => {
  return requestInstance.post("/api/v1/verification/send-acceptance", {
    email,
  });
};
