import AboutUs from "../pages/private/AboutUs";
import AddEditUser from "../pages/private/AddEditUser";
import EmailVerificationPage from "../pages/private/EmailVerificationPage";
import Home from "../pages/private/Home";
import PaymentFailurePage from "../pages/private/PaymentFailurePage";
import PayementOptionsPage from "../pages/private/PaymentOptionsPage";
import PaymentSuccessPage from "../pages/private/PaymentSuccessPage";
import Resources from "../pages/private/Resources";
import Stats from "../pages/private/Stats";
import Users from "../pages/private/Users";
import ForgetPassword from "../pages/public/ForgetPassword";
import Login from "../pages/public/Login";
import NewPassword from "../pages/public/NewPassword";
import SignUp from "../pages/public/SignUp";
import ThanksPage from "../pages/public/ThanksPage";

const hasPermission = (roles) => {
  const user = JSON.parse(localStorage.getItem("user"));
  if (user && roles.includes(user.role)) {
    return true;
  } else {
    return false;
  }
};

const authProtectedRoutes = [
  {
    path: "/",
    component: Home,
    hasPermission: true,
  },
  {
    path: "/users",
    component: Users,
    hasPermission: true,
  },
  {
    path: "/users/add-user",
    component: AddEditUser,
    hasPermission: true,
  },
  {
    path: "/users/edit-user/:id",
    component: AddEditUser,
    hasPermission: true,
  },
  {
    path: "/about",
    component: AboutUs,
    hasPermission: true,
  },
  {
    path: "/resources",
    component: Resources,
    hasPermission: true,
  },
  {
    path: "/stats",
    component: Stats,
    hasPermission: true,
  },
];

const authProtectedRegisteratonRoutes = [
  {
    path: "email-verification",
    component: EmailVerificationPage,
    hasPermission: true,
  },
  {
    path: "/payment-options",
    component: PayementOptionsPage,
    hasPermission: true,
  },
  {
    path: "/payment-success",
    component: PaymentSuccessPage,
    hasPermission: true,
  },
  {
    path: "/payment-cancel",
    component: PaymentFailurePage,
    hasPermission: true,
  },
];

const publicRoutes = [
  { path: "/login", component: Login },
  { path: "/register", component: SignUp },
  {
    path: "/thank-you",
    component: ThanksPage,
  },
  {
    path: "/forget-password",
    component: ForgetPassword,
  },
  {
    path: "/new-password",
    component: NewPassword,
  },
];

export { authProtectedRoutes, publicRoutes, authProtectedRegisteratonRoutes };
