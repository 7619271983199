import React from "react";
import { useDisclosure, IconButton } from "@chakra-ui/react";
import { AddIcon } from "@chakra-ui/icons";

import BuyCoinsModalModal from "./BuyCoinsModalModal";

function BuyCoinsModal() {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <IconButton
        aria-label="Add Kato Coins"
        icon={<AddIcon />}
        bg={"#41b1ee"}
        color={"white"}
        _hover={{
          border: "1px solid #41b1ee",
          bg: "transparent",
          color: "#41b1ee",
        }}
        onClick={onOpen}
      />
      <BuyCoinsModalModal
        {...{
          isOpen,
          handleClose: onClose,
        }}
      />
    </>
  );
}

export default BuyCoinsModal;
