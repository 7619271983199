import React from "react";
import { Navigate } from "react-router-dom";

const PrivateResource = ({
  component: Component,
  layout: Layout,
  hasPermission,
  ...rest
}) => {
  if (localStorage.getItem("user")) {
    if (hasPermission) {
      return (
        <Layout>
          <Component {...rest} />
        </Layout>
      );
    } else {
      return <Navigate to="/login" />;
    }
  } else {
    return <Navigate to="/login" />;
  }
};

export default PrivateResource;
