import { create } from "zustand";

export const useCoinStore = create((set) => ({
  coins: 0,
  exchangeRate: 0,
  numberOfKatoRequests: 0,
  setCoins: (coins) => set({ coins: coins }),
  setExchangeRate: (exchangeRate) => set({ exchangeRate: exchangeRate }),
  setNumberOfKatoRequests: (numberOfKatoRequests) =>
    set({ numberOfKatoRequests: numberOfKatoRequests }),
}));
