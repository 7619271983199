import React from "react";
import { Flex, Text, Stack, Image } from "@chakra-ui/react";
import "@fontsource/poppins/400.css";
import "@fontsource/poppins/700.css";
import KatoBlack from "../../assets/images/NavLogo.png";

function ThanksPage() {
  return (
    <Flex
      w="100%"
      minH={{ base: "100vh", md: "100vh", lg: "100vh", xl: "100vh" }}
      bg={"linear-gradient(150deg, #54a1cc 0%, #8752cc 100%)"}
      py={{ base: "20px", md: "20px", lg: "20px", xl: "20px" }}
      alignItems={"center"}
      justifyContent={"center"}
    >
      <Stack
        spacing={4}
        w={"full"}
        p={6}
        my={10}
        textAlign="center"
        align={"center"}
      >
        <Image
          margin={{
            base: "5px",
            md: "5px",
            lg: "5px",
            xl: "5px",
          }}
          src={KatoBlack}
          w={{
            base: "300px",
            md: "300px",
            lg: "300px",
            xl: "400px",
          }}
        />
        <Text fontSize="48px" fontWeight={"bold"} color="white">
          Thank You for Registering!
        </Text>
        <Stack spacing={1}>
          <Text fontSize="24px" fontWeight={"bold"} color="white">
            You Have Joined the Waitlist
          </Text>
          <Text color="white">We Will Notify You When We Launch</Text>
        </Stack>
      </Stack>
    </Flex>
  );
}

export default ThanksPage;
