import React, { useEffect, useState } from "react";
import {
  Box,
  Container,
  Divider,
  Flex,
  HStack,
  IconButton,
  Stack,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
// import Divider from "../components/Divider";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import Messages from "../../components/Messages";
import { useSearchParams } from "react-router-dom";
import {
  getMessagesBySession,
  sendMessageForSpecificSession,
} from "../../api/chat";
import SessionBar from "../../components/sessionBar/SessionBar";
import { AiOutlineHistory } from "react-icons/ai";
import { useQuotaStore } from "../../store/quota";
import { useCoinStore } from "../../store/coin";
import { useImportantStatsStore } from "../../store/importantStats";
import BuyCoinsModal from "../../components/KatoCoin/BuyCoinsModal";
import BuyCoinsModalModal from "../../components/KatoCoin/BuyCoinsModalModal";
import { useSessionNameStore } from "../../store/sessionName";

function Home() {
  const [messages, setMessages] = useState([]);
  const [messagesLoading, setMessagesLoading] = useState(false);
  const [inputMessage, setInputMessage] = useState("");
  const [response, setResponse] = useState("");
  const [isStreaming, setIsStreaming] = useState(false);
  const [messagesCount, setMessagesCount] = useState(0);
  const [scrollToBottom, setScrollToBottom] = useState(false);
  const user = JSON.parse(localStorage.getItem("user"));
  const userID = user._id;
  const { isOpen, onClose, onOpen } = useDisclosure();
  const [searchParams, setSearchParams] = useSearchParams();
  const toast = useToast();
  const [currentSession, setCurrentSession] = useState(null);
  const { setCoins } = useCoinStore();
  const { setCurrentUserUsage, currentUserUsage, maxQuota } = useQuotaStore();
  const { setNewSessionCreated } = useImportantStatsStore();
  const { setSessionName } = useSessionNameStore();

  const KatoCoinModal = useDisclosure();

  useEffect(() => {
    const cancelledPayment = searchParams.get("cancelledPayment");
    if (cancelledPayment === "true") {
      toast({
        title: "Payment Failed",
        status: "warning",
        duration: 9000,
        isClosable: true,
        position: "top",
      });
      // remove the cancelledPayment query param
      setSearchParams({});
    }

    getMessagesBySession(searchParams.get("sessionID"))
      .then((res) => {
        setMessages(res.data.data.messages);
        setMessagesCount(res.data.data.messagesCount);
        setSearchParams({ sessionID: res.data.data.session._id });
        setCurrentSession(res.data.data.session);
        setSessionName(res.data.data.session.name);
      })
      .catch((err) => {});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  const handleSendMessage = () => {
    if (!inputMessage.trim().length) {
      return;
    }
    setResponse("");
    setInputMessage("");
    setScrollToBottom(true);

    sendMessageForSpecificSession(inputMessage, searchParams.get("sessionID"))
      .then((res) => {
        setSearchParams({ sessionID: res.data.chatbaseResponseSaved.session });
        setCoins(res.data.katoCoins);
        if (currentUserUsage <= maxQuota) {
          setCurrentUserUsage(currentUserUsage + 1);
        }
        console.log("res.data.currentUsage", res.data.currentUsage);
        setMessages((messages) => [
          ...messages,
          res.data.chatbaseResponseSaved,
        ]);
        if (!searchParams.get("sessionID")) {
          setNewSessionCreated(true);
        }
        setScrollToBottom(true);
      })
      .catch((err) => {
        console.log("TEST", err.response.data.error);
        console.log("TEST", err.response.data.data);
        if (
          err.response.data &&
          err.response.data.data &&
          err.response.data.data.showModal
        ) {
          KatoCoinModal.onOpen();
        }

        toast({
          title: err.response.data.error,
          status: "error",
          duration: 9000,
          isClosable: true,
          position: "top",
        });
      });

    const events = new EventSource(
      process.env.REACT_APP_API_URL + `/api/v1/chat/stream`
    );
    // listen to a particular event
    events.addEventListener(userID, (event) => {
      setIsStreaming(true);
      const parsedData = JSON.parse(event.data);
      if (parsedData && parsedData !== "done") {
        setResponse((response) => response + parsedData);
        setScrollToBottom(true);
      } else {
        events.close();
        setIsStreaming(false);
        setScrollToBottom(false);
      }
    });

    const data = inputMessage;

    setMessages((messages) => [...messages, { from: "me", message: data }]);

    if (isStreaming === false) {
    }
  };

  return (
    <>
      <BuyCoinsModalModal
        isOpen={KatoCoinModal.isOpen}
        handleClose={KatoCoinModal.onClose}
      />
      <Stack
        display={"flex"}
        direction="row"
        spacing={4}
        align={"center"}
        justifyContent={"space-between"}
      >
        <Box
          display={"flex"}
          h={"100vh"}
          zIndex={10}
          p={0}
          m={0}
          flexShrink={50}
          alignContent={"flex-start"}
        >
          <SessionBar
            isOpen={isOpen}
            onClose={onClose}
            onOpen={onOpen}
            setChats={setMessages}
            setScrollToBottom={setScrollToBottom}
          />
        </Box>
        <Stack
          w={"100%"}
          // mt={{
          //   base: 20,
          //   md: "130px",
          //   lg: "120px",
          //   xl: "120px",
          // }}
          alignItems={"center"}
        >
          {/* <Box
            display={"flex"}
            w="100%"
            // flexGrow={7}
            // h={{
            //   base: "80vh",
            //   md: "60vh",
            //   lg: "80vh",
            //   xl: "80vh",
            // }}
            justify="center"
            align="left"
            zIndex={10}
          > */}
          {/* <Container
              maxW={{
                base: "container.xs",
                md: "container.md",
                lg: "container.lg",
                xl: "container.lg",
              }}
              w={{
                base: "100%",
                md: "100%",
                lg: "100%",
                xl: "container.md",
              }}
              h="100%"
              p={5}
            > */}
          <Stack
            spacing={0}
            as={Container}
            maxW={{
              base: "container.xs",
              md: "container.xs",
              lg: "container.lg",
              xl: "container.lg",
            }}
            w="100%"
            p={0}
            mx={5}
            h={"80vh"}
          >
            <HStack spacing={5} w="100%" justify="space-between">
              <IconButton
                aria-label="Search database"
                icon={
                  <AiOutlineHistory
                    display={{
                      base: "flex",
                      md: "flex",
                      lg: "none",
                      xl: "none",
                    }}
                  />
                }
                display={{
                  base: "flex",
                  md: "flex",
                  lg: "none",
                  xl: "none",
                }}
                onClick={(e) => {
                  e.preventDefault();
                  onOpen();
                }}
                zIndex={10}
              />
              <Header
                messages={messages}
                datePicker={
                  null
                  // <DatePicker value={selectedDay} onChange={setSelectedDay} />
                }
              />
            </HStack>
            <Divider p={2} />
            <Messages
              loading={messagesLoading}
              messages={messages}
              response={response}
              isStreaming={isStreaming}
              scrollToBottom={scrollToBottom}
              messagesCount={messagesCount}
              setMessages={setMessages}
              setScrollToBottom={setScrollToBottom}
              // targetRef={targetRef}
            />
            <Divider />

            <Footer
              deletedChat={
                currentSession !== null ? currentSession?.deleted : false
              }
              inputMessage={inputMessage}
              setInputMessage={setInputMessage}
              handleSendMessage={handleSendMessage}
              messagesLength={messages.length}
            />
          </Stack>
        </Stack>
        {/* </Container> */}
        {/* </Box> */}
      </Stack>
    </>
  );
}

export default Home;
