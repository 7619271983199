import {
  Box,
  Flex,
  Text,
  Button,
  Stack,
  Collapse,
  Icon,
  Popover,
  PopoverTrigger,
  useColorModeValue,
  useDisclosure,
  useToast,
  IconButton,
  Image,
  Badge,
} from "@chakra-ui/react";
import { ChevronDownIcon, CloseIcon, HamburgerIcon } from "@chakra-ui/icons";
import { logout } from "../api/auth";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import katoBlack from "../assets/images/KatoByNP.png";
import "../index.css";
import { Link } from "react-router-dom";

export default function Navbar({ children }) {
  const { isOpen, onToggle } = useDisclosure();
  const refreshToken = Cookies.get("refreshToken");
  const navigate = useNavigate();
  const toast = useToast();

  return (
    <>
      <Box
        w={"100%"}
        zIndex={999}
        position={"fixed"}
        boxShadow={"rgb(73 73 73 / 24%) 0px -7px 14px 0px"}
        className="no-scroll"
      >
        <Flex
          bg={useColorModeValue("white", "gray.800")}
          color={useColorModeValue("gray.600", "white")}
          minH={"60px"}
          py={{ base: 2 }}
          px={{ base: 4 }}
          borderBottom={1}
          borderStyle={"solid"}
          borderColor={useColorModeValue("gray.200", "gray.900")}
          align={"center"}
          className="no-scroll"
        >
          <Flex
            flex={{ base: 1, md: "auto" }}
            ml={{ base: -2 }}
            display={{ base: "flex", md: "none" }}
          >
            <IconButton
              onClick={onToggle}
              icon={
                isOpen ? (
                  <CloseIcon w={3} h={3} />
                ) : (
                  <HamburgerIcon w={5} h={5} />
                )
              }
              variant={"ghost"}
              aria-label={"Toggle Navigation"}
            />
          </Flex>
          <Flex
            flex={{ base: 1 }}
            justify={{ base: "center", md: "start" }}
            alignItems="center"
            margin={"2px"}
          >
            {/* <Text
              textAlign={useBreakpointValue({ base: "center", md: "left" })}
              fontFamily={"heading"}
              color={useColorModeValue("gray.800", "white")}
              fontWeight={"bold"}
              fontSize="xl"
            >
              Mr. Naggar Bot
            </Text> */}
            <Image
              src={katoBlack}
              alt="Mr. Naggar Bot"
              height={{
                base: "40px",
                md: "40px",
                lg: "50px",
                xl: "55px",
              }}
              onClick={() => navigate("/")}
            />
            {/* <Badge
              colorScheme="blue"
              variant={"outline"}
              rounded={"md"}
              position={"relative"}
              mx={{ base: "1", md: "1" }}
              // top={{
              //   base: "0",
              //   md: "0",
              //   lg:"8px",
              //   xl: "-8px",
              // }}
              // right={{
              //   base: "0",
              //   md: "0",
              //   lg: "10px",
              //   xl: "10px",
              // }}
              fontSize={{
                base: "8px",
                md: "8px",
                lg: "10px",
                xl: "10px",
              }}
            >
              Experiment
            </Badge> */}
            {/* <Badge
              bg="red.300"
              transform={"rotate(30deg)"}
              rounded={"full"}
              position={"relative"}
              right={"16px"}
              fontSize={"0.1em"}
            >
              {" "}
              IN-PROGRESS{" "}
            </Badge> */}

            <Flex display={{ base: "none", md: "flex" }} ml={10}>
              <DesktopNav />
            </Flex>
          </Flex>

          <Stack
            flex={{ base: 1, md: 0 }}
            justify={"flex-end"}
            direction={"row"}
            spacing={6}
          >
            <Button
              as={"a"}
              display={"inline-flex"}
              fontSize={"sm"}
              fontWeight={600}
              color={"white"}
              bg={"black"}
              href={"#"}
              _hover={{
                bg: "gray.800",
              }}
              onClick={() => {
                logout(refreshToken)
                  .then((res) => {
                    if (res.status === 200) {
                      toast({
                        title: "Logged out successfully",
                        status: "success",
                        duration: 3000,
                        isClosable: true,
                        position: "top",
                        styleConfig: {
                          bg: "green.500",
                          color: "white",
                          screenTop: "100px",
                        },
                      });

                      Cookies.remove("refreshToken");
                      Cookies.remove("accessToken");
                      localStorage.removeItem("user");
                      navigate("/login");
                    }
                  })
                  .catch((err) => {});
              }}
            >
              Logout
            </Button>
            {/* <Button
            as={"a"}
            display={{ base: "none", md: "inline-flex" }}
            fontSize={"sm"}
            fontWeight={600}
            color={"white"}
            bg={"pink.400"}
            href={"#"}
          >
            Sign Up
          </Button> */}
          </Stack>
        </Flex>

        <Collapse in={isOpen} animateOpacity>
          <MobileNav />
        </Collapse>
      </Box>
      <Box className="no-scroll" position={"relative"}>
        {children}
      </Box>
    </>
  );
}

const DesktopNav = () => {
  const linkColor = useColorModeValue("gray.600", "gray.200");
  const linkHoverColor = useColorModeValue("gray.800", "white");
  const user = JSON.parse(localStorage.getItem("user"));
  const isAdmin = user?.role === "admin";

  return (
    <Stack direction={"row"} spacing={4}>
      {NAV_ITEMS.map((navItem) =>
        isAdmin ? (
          <Popover
            key={navItem.label}
            trigger={"hover"}
            placement={"bottom-start"}
          >
            <PopoverTrigger>
              <Link
                p={2}
                to={navItem.href ?? "#"}
                fontSize={"sm"}
                fontWeight={500}
                color={linkColor}
                _hover={{
                  textDecoration: "none",
                  color: linkHoverColor,
                }}
              >
                {navItem.label}
              </Link>
            </PopoverTrigger>
          </Popover>
        ) : (
          navItem.onlyForAdmin === false && (
            <Popover
              key={navItem.label}
              trigger={"hover"}
              placement={"bottom-start"}
            >
              <PopoverTrigger>
                <Link
                  p={2}
                  to={navItem.href ?? "#"}
                  fontSize={"sm"}
                  fontWeight={500}
                  color={linkColor}
                  _hover={{
                    textDecoration: "none",
                    color: linkHoverColor,
                  }}
                >
                  {navItem.label}
                </Link>
              </PopoverTrigger>
            </Popover>
          )
        )
      )}
    </Stack>
  );
};

// const DesktopSubNav = ({ label, href, subLabel }) => {
//   return (
//     <Link
//       to={href}
//       role={"group"}
//       display={"block"}
//       p={2}
//       rounded={"md"}
//       _hover={{ bg: useColorModeValue("pink.50", "gray.900") }}
//     >
//       <Stack direction={"row"} align={"center"}>
//         <Box>
//           <Text
//             transition={"all .3s ease"}
//             _groupHover={{ color: "pink.400" }}
//             fontWeight={500}
//           >
//             {label}
//           </Text>
//           <Text fontSize={"sm"}>{subLabel}</Text>
//         </Box>
//         <Flex
//           transition={"all .3s ease"}
//           transform={"translateX(-10px)"}
//           opacity={0}
//           _groupHover={{ opacity: "100%", transform: "translateX(0)" }}
//           justify={"flex-end"}
//           align={"center"}
//           flex={1}
//         >
//           <Icon color={"pink.400"} w={5} h={5} as={ChevronRightIcon} />
//         </Flex>
//       </Stack>
//     </Link>
//   );
// };

const MobileNav = () => {
  const user = JSON.parse(localStorage.getItem("user"));
  const isAdmin = user?.role === "admin";
  return (
    <Stack
      bg={useColorModeValue("white", "gray.800")}
      p={4}
      display={{ md: "none" }}
    >
      {NAV_ITEMS.map((navItem) =>
        isAdmin ? (
          <MobileNavItem key={navItem.label} {...navItem} />
        ) : (
          navItem.onlyForAdmin === false && (
            <MobileNavItem key={navItem.label} {...navItem} />
          )
        )
      )}
    </Stack>
  );
};

const MobileNavItem = ({ label, children, href }) => {
  const { isOpen, onToggle } = useDisclosure();

  return (
    <Stack spacing={4} onClick={children && onToggle}>
      <Flex
        py={2}
        as={Link}
        to={href ?? "#"}
        justify={"space-between"}
        align={"center"}
        _hover={{
          textDecoration: "none",
        }}
      >
        <Text
          fontWeight={600}
          color={useColorModeValue("gray.600", "gray.200")}
        >
          {label}
        </Text>
        {children && (
          <Icon
            as={ChevronDownIcon}
            transition={"all .25s ease-in-out"}
            transform={isOpen ? "rotate(180deg)" : ""}
            w={6}
            h={6}
          />
        )}
      </Flex>

      <Collapse in={isOpen} animateOpacity style={{ marginTop: "0!important" }}>
        <Stack
          mt={2}
          pl={4}
          borderLeft={1}
          borderStyle={"solid"}
          borderColor={useColorModeValue("gray.200", "gray.700")}
          align={"start"}
        >
          {children &&
            children.map((child) => (
              <Link key={child.label} py={2} to={child.href}>
                {child.label}
              </Link>
            ))}
        </Stack>
      </Collapse>
    </Stack>
  );
};

const NAV_ITEMS = [
  {
    label: "Home",
    href: "/",
    onlyForAdmin: false,
  },
  {
    label: "Users",
    href: "/users",
    onlyForAdmin: true,
  },
  {
    label: "Resources",
    href: "/resources",
    onlyForAdmin: true,
  },
  {
    label: "About Us",
    href: "/about",
    onlyForAdmin: false,
  },
  {
    label: "Statistics",
    href: "/stats",
    onlyForAdmin: true,
  },

  //   {
  //     label: "Inspiration",
  //     children: [
  //       {
  //         label: "Explore Design Work",
  //         subLabel: "Trending Design to inspire you",
  //         href: "#",
  //       },
  //       {
  //         label: "New & Noteworthy",
  //         subLabel: "Up-and-coming Designers",
  //         href: "#",
  //       },
  //     ],
  //   },
  //   {
  //     label: "Find Work",
  //     children: [
  //       {
  //         label: "Job Board",
  //         subLabel: "Find your dream design job",
  //         href: "#",
  //       },
  //       {
  //         label: "Freelance Projects",
  //         subLabel: "An exclusive list for contract work",
  //         href: "#",
  //       },
  //     ],
  //   },
  //   {
  //     label: "Learn Design",
  //     href: "#",
  //   },
  //   {
  //     label: "Hire Designers",
  //     href: "#",
  //   },
];
