import {
  Box,
  Checkbox,
  Stack,
  Button,
  Text,
  useColorModeValue,
  Link,
  Image,
  Flex,
  useToast,
  FormControl,
  FormErrorMessage,
  Grid,
} from "@chakra-ui/react";
import React, { useState } from "react";
import KatoBlack from "../../assets/images/NavLogo.png";
import { useFormik } from "formik";
import { registerFormValidationSchema } from "../../validation/validation";
import { isValidPhoneNumber } from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { useNavigate } from "react-router-dom";
import FormPhoneNumberField from "../../components/Fields/FormPhoneNumberField";
import FormPasswordField from "../../components/Fields/FormPasswordField";
import FormTextField from "../../components/Fields/FormTextField";
import FormSelectField from "../../components/Fields/FormSelectField";
import { createUser } from "../../api/auth";
import Cookies from "js-cookie";

function SignUp() {
  const [showPassword, setShowPassword] = useState(false);
  const countries = [
    { name: "Afghanistan", code: "AF" },
    { name: "Åland Islands", code: "AX" },
    { name: "Albania", code: "AL" },
    { name: "Algeria", code: "DZ" },
    { name: "American Samoa", code: "AS" },
    { name: "AndorrA", code: "AD" },
    { name: "Angola", code: "AO" },
    { name: "Anguilla", code: "AI" },
    { name: "Antarctica", code: "AQ" },
    { name: "Antigua and Barbuda", code: "AG" },
    { name: "Argentina", code: "AR" },
    { name: "Armenia", code: "AM" },
    { name: "Aruba", code: "AW" },
    { name: "Australia", code: "AU" },
    { name: "Austria", code: "AT" },
    { name: "Azerbaijan", code: "AZ" },
    { name: "Bahamas", code: "BS" },
    { name: "Bahrain", code: "BH" },
    { name: "Bangladesh", code: "BD" },
    { name: "Barbados", code: "BB" },
    { name: "Belarus", code: "BY" },
    { name: "Belgium", code: "BE" },
    { name: "Belize", code: "BZ" },
    { name: "Benin", code: "BJ" },
    { name: "Bermuda", code: "BM" },
    { name: "Bhutan", code: "BT" },
    { name: "Bolivia", code: "BO" },
    { name: "Bosnia and Herzegovina", code: "BA" },
    { name: "Botswana", code: "BW" },
    { name: "Bouvet Island", code: "BV" },
    { name: "Brazil", code: "BR" },
    { name: "British Indian Ocean Territory", code: "IO" },
    { name: "Brunei Darussalam", code: "BN" },
    { name: "Bulgaria", code: "BG" },
    { name: "Burkina Faso", code: "BF" },
    { name: "Burundi", code: "BI" },
    { name: "Cambodia", code: "KH" },
    { name: "Cameroon", code: "CM" },
    { name: "Canada", code: "CA" },
    { name: "Cape Verde", code: "CV" },
    { name: "Cayman Islands", code: "KY" },
    { name: "Central African Republic", code: "CF" },
    { name: "Chad", code: "TD" },
    { name: "Chile", code: "CL" },
    { name: "China", code: "CN" },
    { name: "Christmas Island", code: "CX" },
    { name: "Cocos (Keeling) Islands", code: "CC" },
    { name: "Colombia", code: "CO" },
    { name: "Comoros", code: "KM" },
    { name: "Congo", code: "CG" },
    { name: "Congo, The Democratic Republic of the", code: "CD" },
    { name: "Cook Islands", code: "CK" },
    { name: "Costa Rica", code: "CR" },
    { name: "Cote D'Ivoire", code: "CI" },
    { name: "Croatia", code: "HR" },
    { name: "Cuba", code: "CU" },
    { name: "Cyprus", code: "CY" },
    { name: "Czech Republic", code: "CZ" },
    { name: "Denmark", code: "DK" },
    { name: "Djibouti", code: "DJ" },
    { name: "Dominica", code: "DM" },
    { name: "Dominican Republic", code: "DO" },
    { name: "Ecuador", code: "EC" },
    { name: "Egypt", code: "EG" },
    { name: "El Salvador", code: "SV" },
    { name: "Equatorial Guinea", code: "GQ" },
    { name: "Eritrea", code: "ER" },
    { name: "Estonia", code: "EE" },
    { name: "Ethiopia", code: "ET" },
    { name: "Falkland Islands (Malvinas)", code: "FK" },
    { name: "Faroe Islands", code: "FO" },
    { name: "Fiji", code: "FJ" },
    { name: "Finland", code: "FI" },
    { name: "France", code: "FR" },
    { name: "French Guiana", code: "GF" },
    { name: "French Polynesia", code: "PF" },
    { name: "French Southern Territories", code: "TF" },
    { name: "Gabon", code: "GA" },
    { name: "Gambia", code: "GM" },
    { name: "Georgia", code: "GE" },
    { name: "Germany", code: "DE" },
    { name: "Ghana", code: "GH" },
    { name: "Gibraltar", code: "GI" },
    { name: "Greece", code: "GR" },
    { name: "Greenland", code: "GL" },
    { name: "Grenada", code: "GD" },
    { name: "Guadeloupe", code: "GP" },
    { name: "Guam", code: "GU" },
    { name: "Guatemala", code: "GT" },
    { name: "Guernsey", code: "GG" },
    { name: "Guinea", code: "GN" },
    { name: "Guinea-Bissau", code: "GW" },
    { name: "Guyana", code: "GY" },
    { name: "Haiti", code: "HT" },
    { name: "Heard Island and Mcdonald Islands", code: "HM" },
    { name: "Holy See (Vatican City State)", code: "VA" },
    { name: "Honduras", code: "HN" },
    { name: "Hong Kong", code: "HK" },
    { name: "Hungary", code: "HU" },
    { name: "Iceland", code: "IS" },
    { name: "India", code: "IN" },
    { name: "Indonesia", code: "ID" },
    { name: "Iran, Islamic Republic Of", code: "IR" },
    { name: "Iraq", code: "IQ" },
    { name: "Ireland", code: "IE" },
    { name: "Isle of Man", code: "IM" },
    { name: "Israel", code: "IL" },
    { name: "Italy", code: "IT" },
    { name: "Jamaica", code: "JM" },
    { name: "Japan", code: "JP" },
    { name: "Jersey", code: "JE" },
    { name: "Jordan", code: "JO" },
    { name: "Kazakhstan", code: "KZ" },
    { name: "Kenya", code: "KE" },
    { name: "Kiribati", code: "KI" },
    { name: "Korea, Democratic People'S Republic of", code: "KP" },
    { name: "Korea, Republic of", code: "KR" },
    { name: "Kuwait", code: "KW" },
    { name: "Kyrgyzstan", code: "KG" },
    { name: "Lao People'S Democratic Republic", code: "LA" },
    { name: "Latvia", code: "LV" },
    { name: "Lebanon", code: "LB" },
    { name: "Lesotho", code: "LS" },
    { name: "Liberia", code: "LR" },
    { name: "Libyan Arab Jamahiriya", code: "LY" },
    { name: "Liechtenstein", code: "LI" },
    { name: "Lithuania", code: "LT" },
    { name: "Luxembourg", code: "LU" },
    { name: "Macao", code: "MO" },
    { name: "Macedonia, The Former Yugoslav Republic of", code: "MK" },
    { name: "Madagascar", code: "MG" },
    { name: "Malawi", code: "MW" },
    { name: "Malaysia", code: "MY" },
    { name: "Maldives", code: "MV" },
    { name: "Mali", code: "ML" },
    { name: "Malta", code: "MT" },
    { name: "Marshall Islands", code: "MH" },
    { name: "Martinique", code: "MQ" },
    { name: "Mauritania", code: "MR" },
    { name: "Mauritius", code: "MU" },
    { name: "Mayotte", code: "YT" },
    { name: "Mexico", code: "MX" },
    { name: "Micronesia, Federated States of", code: "FM" },
    { name: "Moldova, Republic of", code: "MD" },
    { name: "Monaco", code: "MC" },
    { name: "Mongolia", code: "MN" },
    { name: "Montserrat", code: "MS" },
    { name: "Morocco", code: "MA" },
    { name: "Mozambique", code: "MZ" },
    { name: "Myanmar", code: "MM" },
    { name: "Namibia", code: "NA" },
    { name: "Nauru", code: "NR" },
    { name: "Nepal", code: "NP" },
    { name: "Netherlands", code: "NL" },
    { name: "Netherlands Antilles", code: "AN" },
    { name: "New Caledonia", code: "NC" },
    { name: "New Zealand", code: "NZ" },
    { name: "Nicaragua", code: "NI" },
    { name: "Niger", code: "NE" },
    { name: "Nigeria", code: "NG" },
    { name: "Niue", code: "NU" },
    { name: "Norfolk Island", code: "NF" },
    { name: "Northern Mariana Islands", code: "MP" },
    { name: "Norway", code: "NO" },
    { name: "Oman", code: "OM" },
    { name: "Pakistan", code: "PK" },
    { name: "Palau", code: "PW" },
    { name: "Palestinian Territory, Occupied", code: "PS" },
    { name: "Panama", code: "PA" },
    { name: "Papua New Guinea", code: "PG" },
    { name: "Paraguay", code: "PY" },
    { name: "Peru", code: "PE" },
    { name: "Philippines", code: "PH" },
    { name: "Pitcairn", code: "PN" },
    { name: "Poland", code: "PL" },
    { name: "Portugal", code: "PT" },
    { name: "Puerto Rico", code: "PR" },
    { name: "Qatar", code: "QA" },
    { name: "Reunion", code: "RE" },
    { name: "Romania", code: "RO" },
    { name: "Russian Federation", code: "RU" },
    { name: "RWANDA", code: "RW" },
    { name: "Saint Helena", code: "SH" },
    { name: "Saint Kitts and Nevis", code: "KN" },
    { name: "Saint Lucia", code: "LC" },
    { name: "Saint Pierre and Miquelon", code: "PM" },
    { name: "Saint Vincent and the Grenadines", code: "VC" },
    { name: "Samoa", code: "WS" },
    { name: "San Marino", code: "SM" },
    { name: "Sao Tome and Principe", code: "ST" },
    { name: "Saudi Arabia", code: "SA" },
    { name: "Senegal", code: "SN" },
    { name: "Serbia and Montenegro", code: "CS" },
    { name: "Seychelles", code: "SC" },
    { name: "Sierra Leone", code: "SL" },
    { name: "Singapore", code: "SG" },
    { name: "Slovakia", code: "SK" },
    { name: "Slovenia", code: "SI" },
    { name: "Solomon Islands", code: "SB" },
    { name: "Somalia", code: "SO" },
    { name: "South Africa", code: "ZA" },
    { name: "South Georgia and the South Sandwich Islands", code: "GS" },
    { name: "Spain", code: "ES" },
    { name: "Sri Lanka", code: "LK" },
    { name: "Sudan", code: "SD" },
    { name: "Suriname", code: "SR" },
    { name: "Svalbard and Jan Mayen", code: "SJ" },
    { name: "Swaziland", code: "SZ" },
    { name: "Sweden", code: "SE" },
    { name: "Switzerland", code: "CH" },
    { name: "Syrian Arab Republic", code: "SY" },
    { name: "Taiwan, Province of China", code: "TW" },
    { name: "Tajikistan", code: "TJ" },
    { name: "Tanzania, United Republic of", code: "TZ" },
    { name: "Thailand", code: "TH" },
    { name: "Timor-Leste", code: "TL" },
    { name: "Togo", code: "TG" },
    { name: "Tokelau", code: "TK" },
    { name: "Tonga", code: "TO" },
    { name: "Trinidad and Tobago", code: "TT" },
    { name: "Tunisia", code: "TN" },
    { name: "Turkey", code: "TR" },
    { name: "Turkmenistan", code: "TM" },
    { name: "Turks and Caicos Islands", code: "TC" },
    { name: "Tuvalu", code: "TV" },
    { name: "Uganda", code: "UG" },
    { name: "Ukraine", code: "UA" },
    { name: "United Arab Emirates", code: "AE" },
    { name: "United Kingdom", code: "GB" },
    { name: "United States", code: "US" },
    { name: "United States Minor Outlying Islands", code: "UM" },
    { name: "Uruguay", code: "UY" },
    { name: "Uzbekistan", code: "UZ" },
    { name: "Vanuatu", code: "VU" },
    { name: "Venezuela", code: "VE" },
    { name: "Viet Nam", code: "VN" },
    { name: "Virgin Islands, British", code: "VG" },
    { name: "Virgin Islands, U.S.", code: "VI" },
    { name: "Wallis and Futuna", code: "WF" },
    { name: "Western Sahara", code: "EH" },
    { name: "Yemen", code: "YE" },
    { name: "Zambia", code: "ZM" },
    { name: "Zimbabwe", code: "ZW" },
  ];
  const navigate = useNavigate();
  const toast = useToast();
  const registerFormik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      country: "",
      jobTitle: "",
      company: "",
      phoneNumber: "",
      password: "",
      confirmPassword: "",
      privacyPolicyAndTerms: false,
    },
    onSubmit: (values) => {
      if (
        values.phoneNumber !== undefined &&
        values.phoneNumber !== "" &&
        !isValidPhoneNumber(values.phoneNumber)
      ) {
        registerFormik.setFieldError("phoneNumber", "Invalid Phone Number");
        return;
      }
      createUser(
        values.firstName,
        values.lastName,
        values.email,
        values.password,
        values.phoneNumber,
        values.country,
        values.jobTitle,
        values.company
      )
        .then((res) => {
          Cookies.set("accessToken", res.data.data.accessToken);
          Cookies.set("refreshToken", res.data.data.refreshToken);
          localStorage.setItem("user", JSON.stringify(res.data.data.user));
          const isInvited = res.data.data.isInvited;
          navigate("/email-verification", { state: { isInvited } });
        })
        .catch((err) => {
          toast({
            title: err.response.data.error,
            status: "error",
            duration: 5000,
            isClosable: true,
            position: "top",
            styleConfig: {
              bg: "red.500",
              color: "white",
              screenTop: "100px",
            },
          });
        });
    },
    validationSchema: registerFormValidationSchema,
  });
  return (
    <Flex
      w="100%"
      minH={{
        base: "100vh",
        md: "100vh",
        lg: "100vh",
        xl: "100vh",
      }}
      bg={"linear-gradient(150deg, #54a1cc 0%, #8752cc 100%)"}
      py={{
        base: "20px",
        md: "20px",
        lg: "20px",
        xl: "20px",
      }}
      alignItems={"center"}
    >
      <Stack
        w="100%"
        h="100%"
        align="center"
        justifyContent={"space-around"}
        direction={{ base: "column", md: "row" }}
      >
        <Image
          margin={{
            base: "5px",
            md: "5px",
            lg: "5px",
            xl: "5px",
          }}
          src={KatoBlack}
          height={{
            base: "100px",
            md: "100px",
            lg: "200px",
            xl: "200px",
          }}
        />

        <Stack
          spacing={8}
          mx={"auto"}
          minW={{
            base: "sm",
            md: "lg",
            lg: "2xl",
            xl: "2xl",
          }}
          px={6}
        >
          <Box
            rounded={"lg"}
            bg={useColorModeValue("white", "gray.700")}
            boxShadow={"lg"}
            p={8}
          >
            <Stack spacing={4}>
              <Text fontSize={"3xl"} fontWeight={"extrabold"}>
                Sign Up
              </Text>
              <Stack spacing={4} direction={{ base: "column", md: "row" }}>
                <FormTextField
                  label="First Name"
                  name="firstName"
                  type="text"
                  // placeholder="First Name"
                  value={registerFormik.values.firstName}
                  onChange={registerFormik.handleChange}
                  error={registerFormik.errors.firstName}
                  touched={registerFormik.touched.firstName}
                  // helperText="Enter your First Name"
                />
                {/* <FormControl
                    id="firstName"
                    isRequired
                    isInvalid={
                      registerFormik.errors.firstName &&
                      registerFormik.touched.firstName
                    }
                  >
                    
                    <FormLabel>First Name</FormLabel>
                    <Input
                      type="text"
                      value={registerFormik.values.firstName}
                      onChange={registerFormik.handleChange}
                      placeholder="First Name"
                      isInvalid={
                        registerFormik.errors.firstName &&
                        registerFormik.touched.firstName
                      }
                    />
                    {!registerFormik.errors.firstName &&
                      !registerFormik.touched.firstName && (
                        <FormHelperText>Enter your First Name</FormHelperText>
                      )}
                    <FormErrorMessage>
                      {registerFormik.errors.firstName &&
                        registerFormik.touched.firstName &&
                        registerFormik.errors.firstName}
                    </FormErrorMessage>
                  </FormControl> */}
                <FormTextField
                  label="Last Name"
                  name="lastName"
                  type="text"
                  // placeholder="Last Name"
                  value={registerFormik.values.lastName}
                  onChange={registerFormik.handleChange}
                  error={registerFormik.errors.lastName}
                  touched={registerFormik.touched.lastName}
                  // helperText="Enter your Last Name"
                />
              </Stack>
              <Stack spacing={4} direction={{ base: "column", md: "row" }}>
                <FormTextField
                  label="Email Address"
                  name="email"
                  type="email"
                  // placeholder="Email Address"
                  value={registerFormik.values.email}
                  onChange={registerFormik.handleChange}
                  error={registerFormik.errors.email}
                  touched={registerFormik.touched.email}
                  // helperText="Enter your Email Address"
                />
                <FormSelectField
                  label="Nationality"
                  name="country"
                  placeholder="Select Nationality"
                  value={registerFormik.values.country}
                  onChange={registerFormik.handleChange}
                  error={registerFormik.errors.country}
                  touched={registerFormik.touched.country}
                  // helperText="Select your country"
                  allOptions={countries}
                />
              </Stack>
              <Grid
                templateColumns={{
                  base: "repeat(1, 1fr)",
                  md: "repeat(1, 1fr)",
                  lg: "repeat(2, 1fr)",
                  xl: "repeat(2, 1fr)",
                }}
                gap={4}
              >
                <FormPhoneNumberField
                  label="Phone Number"
                  name="phoneNumber"
                  // placeholder="Phone Number"
                  value={registerFormik.values.phoneNumber}
                  onChange={(value) =>
                    registerFormik.setFieldValue("phoneNumber", value)
                  }
                  error={
                    registerFormik.errors.phoneNumber ||
                    (registerFormik.values.phoneNumber &&
                      registerFormik.values.phoneNumber !== undefined &&
                      !isValidPhoneNumber(registerFormik.values.phoneNumber) &&
                      true)
                  }
                  touched={registerFormik.touched.phoneNumber}
                  // helperText="Enter your Phone Number"
                />
              </Grid>
              <Stack spacing={4} direction={{ base: "column", md: "row" }}>
                <FormTextField
                  label="Job Title"
                  name="jobTitle"
                  type="text"
                  // placeholder="Job Title"
                  value={registerFormik.values.jobTitle}
                  onChange={registerFormik.handleChange}
                  error={registerFormik.errors.jobTitle}
                  touched={registerFormik.touched.jobTitle}
                  // helperText="Enter your Job Title"
                />
                <FormTextField
                  label="Company"
                  name="company"
                  type="text"
                  // placeholder="Company"
                  value={registerFormik.values.company}
                  onChange={registerFormik.handleChange}
                  error={registerFormik.errors.company}
                  touched={registerFormik.touched.company}
                  // helperText="Enter your Company"
                />
              </Stack>
              <Stack spacing={4} direction={{ base: "column", md: "row" }}>
                <FormPasswordField
                  showPassword={showPassword}
                  setShowPassword={setShowPassword}
                  label="Password"
                  name="password"
                  // placeholder="Password"
                  value={registerFormik.values.password}
                  onChange={registerFormik.handleChange}
                  error={registerFormik.errors.password}
                  touched={registerFormik.touched.password}
                  // helperText="Enter your Password"
                />
                <FormPasswordField
                  showPassword={showPassword}
                  setShowPassword={setShowPassword}
                  label="Confirm Password"
                  name="confirmPassword"
                  // placeholder="Confirm Password"
                  value={registerFormik.values.confirmPassword}
                  onChange={registerFormik.handleChange}
                  error={registerFormik.errors.confirmPassword}
                  touched={registerFormik.touched.confirmPassword}
                  // helperText="Enter your Password"
                />
              </Stack>
              <FormControl
                isInvalid={registerFormik.errors.privacyPolicyAndTerms}
              >
                <Stack spacing={4} direction={"row"}>
                  <Checkbox
                    value={registerFormik.values.privacyPolicyAndTerms}
                    onChange={registerFormik.handleChange}
                    name="privacyPolicyAndTerms"
                    colorScheme="blue"
                    size="lg"
                  />
                  <Text fontSize={"sm"} color={"gray.600"}>
                    I agree to the{" "}
                    <Link
                      color={"blue.400"}
                      onClick={() => {
                        window.open(
                          "https://katolegal.com/privacy-policy/",
                          "_blank"
                        );
                      }}
                    >
                      Privacy Policy
                    </Link>
                    {" and "}
                    <Link
                      color={"blue.400"}
                      onClick={() => {
                        window.open(
                          "https://katolegal.com/terms-of-use/",
                          "_blank"
                        );
                      }}
                    >
                      Terms of Service
                    </Link>
                  </Text>
                </Stack>

                {registerFormik.errors.privacyPolicyAndTerms && (
                  <FormErrorMessage>
                    {registerFormik.errors.privacyPolicyAndTerms}
                  </FormErrorMessage>
                )}
              </FormControl>
              <Stack
                spacing={10}
                pt={2}
                direction={{ base: "column", md: "row" }}
                w={"full"}
              >
                <Button
                  loadingText="Submitting"
                  w={"full"}
                  onClick={registerFormik.handleSubmit}
                  size="lg"
                  bg={"blue.400"}
                  color={"white"}
                  _hover={{
                    bg: "blue.500",
                  }}
                >
                  Sign up
                </Button>
                <Stack pt={6} w={"full"}>
                  <Text align={"center"}>
                    Already a user?{" "}
                    <Link
                      color={"blue.400"}
                      onClick={() => {
                        navigate("/login");
                      }}
                    >
                      Login
                    </Link>
                  </Text>
                </Stack>
              </Stack>
            </Stack>
          </Box>
        </Stack>
      </Stack>
    </Flex>
  );
}

export default SignUp;
