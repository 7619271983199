import requestInstance from "./requestInstance";

export const getNSuggeestedQuestions = (n) => {
  return requestInstance.get(
    `/api/v1/suggested-questions/get-n-questions/${n}`
  );
};

export const incrementNumOfUsage = (questionID) => {
  return requestInstance.put(
    `/api/v1/suggested-questions/increment/${questionID}`
  );
};
