import React, { useEffect } from "react";
import {
  Flex,
  useColorModeValue,
  Button,
  HStack,
  FormControl,
  FormLabel,
  Input,
  FormHelperText,
  FormErrorMessage,
  Heading,
  Stack,
  useToast,
} from "@chakra-ui/react";
import { useNavigate, useParams } from "react-router-dom";
import { useFormik } from "formik";
import { registerValidationSchema } from "../../validation/validation";
import { register, updateUser } from "../../api/auth";
import { getUser } from "../../api/user";
function AddEditUser() {
  const { id } = useParams();
  const isAddMode = !id;
  const toast = useToast();
  const navigate = useNavigate();
  const userFormik = useFormik({
    initialValues: {
      name: "",
      email: "",
      password: "",
    },
    onSubmit: (values) => {
      if (isAddMode) {
        register(values.name, values.email, values.password)
          .then((res) => {
            toast({
              title: res.data.message,
              status: "success",
              duration: 9000,
              isClosable: true,
              position: "top",
            });
            navigate("/users");
          })
          .catch((err) => {
            toast({
              title: err.response.data.error,
              status: "error",
              duration: 9000,
              isClosable: true,
              position: "top",
            });
          });
      } else {
        updateUser(id, values.name, values.email, values.password)
          .then((res) => {
            toast({
              title: res.data.message,
              status: "success",
              duration: 9000,
              isClosable: true,
              position: "top",
            });
            navigate("/users");
          })
          .catch((err) => {
            toast({
              title: err.response.data.error,
              status: "error",
              duration: 9000,
              isClosable: true,
              position: "top",
            });
          });
      }
    },
    validationSchema: registerValidationSchema,
  });
  useEffect(() => {
    if (!isAddMode) {
      getUser(id).then((res) => {
        userFormik.setValues({
          name: res.data.data.user.name,
          email: res.data.data.user.email,
          password: "",
        });
      });
    }
  }, []);

  return (
    <Flex align={"center"} justify={"center"} p={4}>
      <Stack spacing={8} alignItems={"center"} w={"full"} m={0} p={0}>
        <Stack
          spacing={4}
          w={{
            base: "full",
            md: "md",
            lg: "md",
            xl: "md",
          }}
          bg={useColorModeValue("white", "gray.700")}
          p={6}
          my={12}
        >
          <Heading size="2xl" fontWeight="extrabold" textAlign={"center"}>
            {isAddMode ? "Add User" : "Edit User"}
          </Heading>
          <FormControl
            id="name"
            mt={4}
            isRequired
            isInvalid={userFormik.errors.name && userFormik.touched.name}
          >
            <FormLabel>Name</FormLabel>
            <Input
              placeholder="name"
              value={userFormik.values.name}
              onChange={userFormik.handleChange}
              isInvalid={userFormik.errors.name && userFormik.touched.name}
              type="text"
            />
            {userFormik.errors.name && userFormik.touched.name ? (
              <FormErrorMessage>{userFormik.errors.name}</FormErrorMessage>
            ) : (
              <FormHelperText fontSize={"xs"}>
                Name of the user you want to create
              </FormHelperText>
            )}
          </FormControl>

          <FormControl
            id="email"
            mt={4}
            isRequired
            isInvalid={userFormik.errors.email && userFormik.touched.email}
          >
            <FormLabel>Email</FormLabel>
            <Input
              placeholder="Email"
              type="email"
              value={userFormik.values.email}
              onChange={userFormik.handleChange}
              isInvalid={userFormik.errors.email && userFormik.touched.email}
            />
            {userFormik.errors.email && userFormik.touched.email ? (
              <FormErrorMessage>{userFormik.errors.email}</FormErrorMessage>
            ) : (
              <FormHelperText fontSize={"xs"}>
                Email of the user you want to create
              </FormHelperText>
            )}
          </FormControl>
          <FormControl
            id="password"
            mt={4}
            isRequired
            isInvalid={
              userFormik.errors.password && userFormik.touched.password
            }
          >
            <FormLabel>Password</FormLabel>
            <Input
              placeholder="Password"
              type="password"
              value={userFormik.values.password}
              onChange={userFormik.handleChange}
              isInvalid={
                userFormik.errors.password && userFormik.touched.password
              }
            />
            {userFormik.touched.password && userFormik.errors.password ? (
              <FormErrorMessage>{userFormik.errors.password}</FormErrorMessage>
            ) : (
              <FormHelperText>write a strong password</FormHelperText>
            )}
          </FormControl>
          <HStack spacing={4} justifyContent={"center"}>
            <Button
              bg={"black"}
              color={"white"}
              type="submit"
              _hover={{
                bg: "gray:800",
              }}
              mr={3}
              onClick={userFormik.handleSubmit}
            >
              {isAddMode ? "Add" : "Edit"}
            </Button>
            <Button
              onClick={() => {
                navigate("/users");
              }}
            >
              Cancel
            </Button>
          </HStack>
        </Stack>
      </Stack>
    </Flex>
  );
}

export default AddEditUser;
