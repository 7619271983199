import requestInstance from "./requestInstance";

export const login = (email, password) => {
  return requestInstance.post("/api/v1/auth/login", { email, password });
};

export const logout = (refreshToken) => {
  return requestInstance.post("/api/v1/auth/logout", { refreshToken });
};

export const register = (name, email, password) => {
  return requestInstance.post("/api/v1/auth/register", {
    name,
    email,
    password,
  });
};

export const createUser = (
  firstName,
  lastName,
  email,
  password,
  phoneNumber,
  country,
  jobTitle,
  company
) => {
  return requestInstance.post("/api/v1/auth/register", {
    firstName,
    lastName,
    email,
    password,
    phoneNumber,
    country,
    jobTitle,
    company,
  });
};

export const updateUser = (id, name, email, password) => {
  return requestInstance.put(`/api/v1/auth/update/${id}`, {
    name,
    email,
    password,
  });
};
export const renewSubscription = (id) => {
  return requestInstance.put(`/api/v1/auth/renew/${id}`);
};

export const forgetPassword = (email) => {
  return requestInstance.post("/api/v1/auth/forget-password", { email });
};

export const renewPassword = (password, id) => {
  return requestInstance.post(`/api/v1/auth/new-password/${id}`, {
    password,
  });
};
