import React, { memo } from "react";

import {
  Box,
  Drawer,
  DrawerCloseButton,
  DrawerContent,
  DrawerOverlay,
} from "@chakra-ui/react";
import "../../index.css";
import SessionBarContent from "./SessionBarContent";
import SessionBarContent2 from "./SessionBarContent2";

function SessionBar({ isOpen, onClose, onOpen, setChats, setScrollToBottom }) {
  return (
    <Box className="no-scroll">
      <Drawer isOpen={isOpen} onClose={onClose} placement="left">
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton color={"white"} />
          <SessionBarContent
            setChats={setChats}
            setScrollToBottom={setScrollToBottom}
          />
          {/* <SessionBarContent2 /> */}
        </DrawerContent>
      </Drawer>
      <Box
        display={{
          base: "none",
          md: "none",
          lg: "flex",
          xl: "flex",
        }}
        backgroundColor={"black"}
        color={"white"}
        w={{
          base: "300px",
          md: "300px",
          lg: "300px",
          xl: "400px",
        }}
        h={{
          lg: "100vh",
          xl: "100vh",
        }}
        align="center"
        justify="center"
        position={{
          lg: "relative",
          xl: "relative",
        }}
        zIndex={800}
      >
        {/* <SessionBarContent2
          setChats={setChats}
          setScrollToBottom={setScrollToBottom}
        /> */}
        <SessionBarContent
          setChats={setChats}
          setScrollToBottom={setScrollToBottom}
        />
      </Box>
    </Box>
  );
}

export default memo(SessionBar);
