import React, { useEffect } from "react";
import {
  Stat,
  StatLabel,
  StatNumber,
  Flex,
  HStack,
  Text,
} from "@chakra-ui/react";
import BuyCoinsModal from "./BuyCoinsModal";
import { useCoinStore } from "../../store/coin";
import { useQuotaStore } from "../../store/quota";

function KatoCoin() {
  const { coins, numberOfKatoRequests } = useCoinStore();
  const { currentUserUsage, maxQuota } = useQuotaStore();

  return (
    <Flex direction={"column"} align={"center"} width={"100%"} px={2}>
      <Stat color={"black"} justifyContent={"space-between"} width={"100%"}>
        <HStack spacing={5} width={"100%"} justifyContent={"space-between"}>
          <HStack spacing={5} width={"100%"}>
            <StatNumber>{coins}.00</StatNumber>
            <StatLabel fontSize={"sm"}>KATO Coins</StatLabel>
          </HStack>

          <BuyCoinsModal />
        </HStack>
        <HStack spacing={5} width={"100%"}>
          {coins > 0 ? (
            <Text fontSize={"xs"}>
              you have <strong>{numberOfKatoRequests} </strong> premimum
              requests left
            </Text>
          ) : (
            <Text fontSize={"xs"}>
              you have <strong> {maxQuota - currentUserUsage}</strong> free
              requests left
            </Text>
          )}
        </HStack>
      </Stat>
    </Flex>
  );
}

export default KatoCoin;
