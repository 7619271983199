import React from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Icon,
  Input,
  Stack,
  Text,
  HStack,
  useNumberInput,
} from "@chakra-ui/react";
import { BsCoin } from "react-icons/bs";
import { createCheckoutSession } from "../../api/payment";
import { useCoinStore } from "../../store/coin";

function BuyCoinsModalModal({ isOpen, handleClose }) {
  const { exchangeRate } = useCoinStore();
  const { getInputProps, getIncrementButtonProps, getDecrementButtonProps } =
    useNumberInput({
      id: "coin",
      name: "coin",
      step: 1,
      defaultValue: 1,
      min: 1,
    });
  const inc = getIncrementButtonProps();
  const dec = getDecrementButtonProps();
  const input = getInputProps();
  const handleBuyCoins = () => {
    // console.log("Buying coins", input.value);
    createCheckoutSession("kato-coins", input.value)
      .then((res) => {
        window.location.href = res.data.data.url;
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <Modal isOpen={isOpen} onClose={handleClose} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Buy KATO Coins</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Stack spacing={3} w="100%" p={0} mb={3} align="center">
            <Icon as={BsCoin} w={24} h={24} />
            <Text fontSize="lg" textAlign="center">
              Buy KATO Coins to get better responses from KATO.
            </Text>
            <HStack spacing={5} width={"100%"} justifyContent={"space-between"}>
              <Button {...inc} colorScheme="blue">
                +
              </Button>
              <Input {...input} size="lg" textAlign="center" />
              <Button {...dec} colorScheme="blue">
                -
              </Button>
            </HStack>
            <Text fontSize="lg" textAlign="center">
              {input.value * exchangeRate} Premimum Requests
            </Text>
          </Stack>
        </ModalBody>

        <ModalFooter>
          <Button variant={"outline"} mr={3} onClick={handleClose}>
            Close
          </Button>
          <Button colorScheme="blue" onClick={handleBuyCoins}>
            Buy Coins
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export default BuyCoinsModalModal;
