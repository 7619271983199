import { Button, Flex, Heading, Icon, Stack, Text } from "@chakra-ui/react";
import React from "react";
import "@fontsource/poppins/800.css";
import { CheckCircleIcon, CheckIcon } from "@chakra-ui/icons";
function Option({ title, advantages, price, special, monthly }) {
  return (
    <Flex
      w="100%"
      maxW={"sm"}
      bg={"white"}
      rounded={"xl"}
      boxShadow={"lg"}
      p={6}
      h={"400px"}
    >
      <Stack spacing={8} w={"full"}>
        <Heading
          lineHeight={1.1}
          fontWeight={"extrabold"}
          fontSize={{ base: "2xl", md: "3xl" }}
          align={"center"}
        >
          {title}
          {special && <span style={{ fontSize: "20px" }}> (Recommended)</span>}
        </Heading>
        <Text fontSize="3xl" fontWeight={"extrabold"} align={"center"}>
          {price} / {monthly ? "month" : "year"}
        </Text>
        <Text color={"gray.500"} lineHeight={1.9} fontSize={"xl"}>
          {advantages.map((advantage) => (
            <>
              <CheckCircleIcon color={"green.500"} />
              {"    "}
              <span>
                {advantage}
                <br />
              </span>
            </>
          ))}
        </Text>
        <Button
          bg={
            special
              ? "linear-gradient(150deg, #54a1cc 0%, #8752cc 100%)"
              : "blue.400"
          }
          color={"white"}
          _hover={{
            bg: special
              ? "linear-gradient(150deg, #54a1cc 0%, #8752cc 100%)"
              : "blue.400",
          }}
          variant={special ? "solid" : "outline"}
          _active={{
            bg: "blue.400",
            transform: "scale(0.95)",
          }}
        >
          Subscribe
        </Button>
      </Stack>
    </Flex>
  );
}

function PaymentOptionsPage() {
  return (
    <Flex
      w="100%"
      minH={{ base: "100%", md: "100vh", lg: "100vh", xl: "100vh" }}
      bg={"linear-gradient(150deg, #54a1cc 0%, #8752cc 100%)"}
      py={{ base: "20px", md: "20px", lg: "20px", xl: "20px" }}
      alignItems={"center"}
      justifyContent={"center"}
    >
      <Stack
        spacing={4}
        w={"full"}
        direction={{ base: "column", md: "row", lg: "row", xl: "row" }}
        alignContent={"center"}
        justifyContent={"center"}
        alignItems={"center"}
        p={6}
      >
        <Option
          title={"Basic"}
          advantages={[
            "200 Requests",
            "24/7 Availability",
            "Secure Data Environment",
          ]}
          price={"$5"}
          monthly={true}
          special={false}
        />
        <Option
          title={"Pro"}
          advantages={[
            "450 Requests",
            "24/7 Availability",
            "Secure Data Environment",
          ]}
          price={"$10"}
          monthly={true}
          special={true}
        />

        <Option
          title={"Advanced"}
          advantages={[
            "700 Requests",
            "24/7 Availability",
            "Secure Data Environment",
          ]}
          price={"$15"}
          monthly={true}
          special={false}
        />
      </Stack>
    </Flex>
  );
}

export default PaymentOptionsPage;
