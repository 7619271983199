import requestInstance from "./requestInstance";
export const getUsers = async (page = 1, perPage = 10) => {
  return requestInstance.get(
    `/api/v1/users/read-all?page=${page}&perPage=${perPage}`
  );
};

export const getUser = async (id) => {
  return requestInstance.get(`/api/v1/users/read/${id}`);
};

export const delteUser = async (id) => {
  return requestInstance.delete(`/api/v1/users/delete/${id}`);
};
