import {
  Button,
  FormControl,
  Flex,
  PinInput,
  PinInputField,
  Stack,
  useColorModeValue,
  Center,
  Heading,
  HStack,
  Text,
  useToast,
} from "@chakra-ui/react";
import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { resendEmailVerification, verifyEmail } from "../../api/verification";
import Cookies from "js-cookie";

function EmailVerificationPage() {
  const navigate = useNavigate();
  const location = useLocation();
  const [success, setSuccess] = React.useState(false);
  const [verificationCode, setVerificationCode] = React.useState("");
  const user = JSON.parse(localStorage.getItem("user"));
  const toast = useToast();
  const previousPageState = location.state;
  console.log(previousPageState);
  const handleResendVerificationCode = () => {
    resendEmailVerification()
      .then((res) => {
        console.log(res);
        setSuccess(true);
      })
      .catch((err) => {
        setSuccess(false);
      });
  };
  const handleEmailCodeVerification = () => {
    if (verificationCode.length !== 6) {
      return;
    }
    verifyEmail(verificationCode)
      .then((res) => {
        if (
          previousPageState &&
          previousPageState.isInvited &&
          res.status === 200
        ) {
          //remove user from local storage and set the user to the new user
          localStorage.removeItem("user");
          localStorage.setItem("user", JSON.stringify(res.data.data.user));
          navigate("/", { state: { isInvited: true, isVerified: true } });
        }
        if (res.status === 200 && !previousPageState.isInvited) {
          navigate("/login");
          localStorage.removeItem("user");
          Cookies.remove("accessToken");
          Cookies.remove("refreshToken");
        }
        // if (res.status === 200) {
        //   navigate("/payment-options");
        // }
      })
      .catch((err) => {
        toast({
          title: err.response.data.error,
          status: "error",
          duration: 9000,
          isClosable: true,
          position: "top",
        });
      });
  };
  return (
    <Flex
      w="100%"
      minH={{ base: "100%", md: "100vh", lg: "100vh", xl: "100vh" }}
      bg={"linear-gradient(150deg, #54a1cc 0%, #8752cc 100%)"}
      py={{ base: "20px", md: "20px", lg: "20px", xl: "20px" }}
      alignItems={"center"}
      justifyContent={"center"}
    >
      <Stack
        spacing={4}
        w={"full"}
        maxW={"sm"}
        bg={useColorModeValue("white", "gray.700")}
        rounded={"xl"}
        boxShadow={"lg"}
        p={6}
        my={10}
      >
        <Center>
          <Heading lineHeight={1.1} fontSize={{ base: "2xl", md: "3xl" }}>
            {previousPageState && previousPageState.isInvited
              ? "Code Verification"
              : "Verify your email"}
          </Heading>
        </Center>
        <Center
          fontSize={{ base: "sm", sm: "md" }}
          color={useColorModeValue("gray.800", "gray.400")}
        >
          {previousPageState && previousPageState.isInvited
            ? "Enter the code in your invitation email"
            : "we have sent a code to your email address."}
        </Center>
        <Center
          fontSize={{ base: "sm", sm: "md" }}
          fontWeight="bold"
          color={useColorModeValue("gray.800", "gray.400")}
        >
          {user.email}
        </Center>
        <FormControl>
          <Center>
            <HStack>
              <PinInput value={verificationCode} onChange={setVerificationCode}>
                <PinInputField />
                <PinInputField />
                <PinInputField />
                <PinInputField />
                <PinInputField />
                <PinInputField />
              </PinInput>
            </HStack>
          </Center>
        </FormControl>
        <Stack spacing={6}>
          <Button
            bg={"blue.400"}
            color={"white"}
            _hover={{
              bg: "blue.500",
            }}
            onClick={handleEmailCodeVerification}
          >
            Verify
          </Button>
        </Stack>
        {previousPageState && !previousPageState.isInvited && (
          <Center>
            {!success && (
              <Text fontSize={{ base: "sm", sm: "md" }} color={"black"}>
                Didn't receive the code?{" "}
                <Button
                  variant={"link"}
                  color={"blue.400"}
                  onClick={handleResendVerificationCode}
                >
                  Resend
                </Button>
              </Text>
            )}
          </Center>
        )}
      </Stack>
    </Flex>
  );
}

export default EmailVerificationPage;
