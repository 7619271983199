import React from "react";
import {
  FormControl,
  FormLabel,
  FormErrorMessage,
  FormHelperText,
  Select,
} from "@chakra-ui/react";

function FormSelectField({
  label,
  name,
  placeholder,
  value,
  onChange,
  error,
  touched,
  helperText,
  allOptions,
}) {
  return (
    <FormControl id={name} isRequired isInvalid={error && touched}>
      <FormLabel>{label}</FormLabel>
      <Select value={value} placeholder={placeholder} onChange={onChange}>
        {allOptions.map((xOption) => (
          <option key={xOption.code} value={xOption.name}>
            {xOption.name}
          </option>
        ))}
      </Select>
      <FormHelperText>{!error && helperText}</FormHelperText>
      <FormErrorMessage>{error && touched && error}</FormErrorMessage>
    </FormControl>
  );
}

export default FormSelectField;
