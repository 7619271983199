import requestInstance from "./requestInstance";

export const sendMessage = (message) => {
  return requestInstance.post("/api/v1/chat/send", { message });
};

export const getMessages = () => {
  return requestInstance.get(`/api/v1/chat/get`);
};

export const getHistoryDays = () => {
  return requestInstance.get(`/api/v1/chat/get-used-days`);
};

export const getMessagesByDay = (day) => {
  return requestInstance.get(`/api/v1/chat/get-chats-by-day?day=${day}`);
};

export const getMessagesBySession = (sessionId) => {
  return requestInstance.get(
    `/api/v1/chat/get-chats-by-session?sessionId=${sessionId}`
  );
};

export const sendMessageForSpecificSession = (message, sessionId) => {
  return requestInstance.post("/api/v1/chat/send-for-session", {
    message,
    sessionId,
  });
};

export const likeMessageAPI = (messageId) => {
  return requestInstance.post(`/api/v1/chat/like-message/${messageId}`);
};

export const dislikeMessageAPI = (messageId) => {
  return requestInstance.post(`/api/v1/chat/dislike-message/${messageId}`);
};
