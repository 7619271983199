import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { ChakraProvider, extendTheme } from "@chakra-ui/react";
import "@fontsource/poppins/400.css";

const KATOTheme = extendTheme({
  colors: {
    brand: {
      50: "#6781ee",
      100: "#4d6ceb",
      200: "#3457e9",
      300: "#1a42e6",
      400: "#012de3",
      500: "#0129cc",
      600: "#0124b6",
      700: "#011f9f",
      800: "#011772",
      900: "#00092d",
    },
  },
  fonts: {
    heading: "Poppins",
    body: "Poppins",
  },
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <ChakraProvider theme={KATOTheme}>
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </ChakraProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
