import React from "react";

import { Page, Text, View, Document, Image } from "@react-pdf/renderer";
import Kato from "../assets/images/KatoByNP.png";
import showdown from "showdown";
import Html from "react-pdf-html";
import { MarkdownToJSX } from "./Messages";
import { Divider } from "@chakra-ui/react";

function PdfContent({ messages }) {
  const converter = new showdown.Converter({
    tables: true,
    simplifiedAutoLink: true,
    strikethrough: true,
    tasklists: true,
    simpleLineBreaks: true,
    openLinksInNewWindow: true,
    emoji: true,
    requireSpaceBeforeHeadingText: true,
    smartIndentationFix: true,
    backslashEscapesHTMLTags: true,
    disableForced4SpacesIndentedSublists: true,
  });
  const messagesList = messages.map((message) => {
    return {
      from: message.from === "computer" ? "KATO" : "You",
      text: (
        <Html
          style={{
            fontSize: "12px",
          }}
        >
          {converter.makeHtml(message.message)}
        </Html>
      ),
    };
  });

  return (
    <Document>
      <Page
        size="A4"
        style={{
          padding: "10px",
          fontSize: "12px",
        }}
      >
        <Image
          src={Kato}
          style={{
            height: "60px",
            width: "148px",
          }}
        />
        {messagesList.map((message, index) => {
          return (
            <View
              key={index}
              style={{
                padding: "10px",
                borderBottom: "1px solid #000",
                fontSize: "12px",
              }}
            >
              <Text>{message.from}</Text>
              {message.text}
            </View>
          );
        })}
      </Page>
    </Document>
  );
}

export default PdfContent;
