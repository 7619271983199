import React, { useEffect, useState } from "react";
import { getStats } from "../../api/stats";
import {
  Box,
  HStack,
  Text,
  VStack,
  Stat,
  StatLabel,
  StatNumber,
  StatHelpText,
  StatArrow,
  StatGroup,
} from "@chakra-ui/react";

import ReactECharts from "echarts-for-react";

function Stats() {
  const [dailyChatsCounts, setDailyChatsCounts] = useState(null);
  const [totalChatCounts, setTotalChatsCount] = useState(0);
  const [totalChatCountsLastMonth, setTotalChatsCountLastMonth] = useState(0);

  useEffect(() => {
    getStats()
      .then((res) => {
        setTotalChatsCount(res.data.totalChats);
        setDailyChatsCounts(res.data.dailyChats);
        setTotalChatsCountLastMonth(res.data.totalChatsLastMonth);
      })
      .catch((err) => console.log(err));
  }, []);

  const option = {
    xAxis: {
      type: "category",
      data: dailyChatsCounts?.map((day, idx) => {
        return day.date;
      }),
    },
    yAxis: {
      type: "value",
    },
    series: [
      {
        data: dailyChatsCounts?.map((day, idx) => {
          return day.count;
        }),
        type: "line",
        label: {
          show: true,
          position: "top",
          formatter: "{c}",
        },
      },
    ],
    tooltip: {
      trigger: "axis",
    },
    title: {
      text: `Daily messages for the last 30 days`,
      left: "center",
    },
  };
  return (
    <Box p={10} width={"100%"} height={"100%"}>
      <br />
      <br />
      <HStack justifyContent={"space-between"}>
        <Text fontSize="3xl">KATO Usage Statistics</Text>
      </HStack>
      <VStack spacing={10} mt={10} alignItems={"flex-start"} width={"100%"}>
        <Box
          width={"100%"}
          height={"200px"}
          p={10}
          bg={"white"}
          borderRadius={5}
          alignContent={"center"}
          border={"1px solid #E2E8F0"}
        >
          <StatGroup width={"100%"}>
            <Stat>
              <StatLabel>Responses From KATO</StatLabel>
              <StatNumber>{totalChatCounts && totalChatCounts}</StatNumber>
              <StatHelpText>
                {totalChatCounts &&
                  totalChatCountsLastMonth &&
                  Math.round(
                    (totalChatCountsLastMonth / totalChatCounts) * 100
                  ) + "% "}
                <StatArrow type="increase" />
                Compared to last 30 days
              </StatHelpText>
            </Stat>

            <Stat>
              <StatLabel>Responses from KATO</StatLabel>
              <StatNumber>
                {totalChatCountsLastMonth && totalChatCountsLastMonth}
              </StatNumber>
              <StatHelpText>
                {/* <StatArrow type="decrease" /> */}
                In the last 30 days
              </StatHelpText>
            </Stat>
          </StatGroup>
        </Box>
        <Box
          width={"100%"}
          height={"500px"}
          p={10}
          bg={"white"}
          borderRadius={5}
          border={"1px solid #E2E8F0"}
        >
          <ReactECharts
            option={option}
            style={{
              width: "100%",
              height: "100%",
            }}
          />
        </Box>
      </VStack>
    </Box>
  );
}

export default Stats;
