import React from "react";
import {
  Flex,
  Avatar,
  AvatarBadge,
  Text,
  Button,
  Badge,
  HStack,
} from "@chakra-ui/react";
import { PDFDownloadLink } from "@react-pdf/renderer";
import PdfContent from "./PdfContent";
import KatoAvatar from "../assets/images/Avatar.png";
import { useQuotaStore } from "../store/quota";
import { useCoinStore } from "../store/coin";
import { useSessionNameStore } from "../store/sessionName";
import { useSearchParams } from "react-router-dom";

const Header = ({ datePicker, messages }) => {
  const { coins } = useCoinStore();
  const { sessionName } = useSessionNameStore();

  return (
    <Flex w="100%" justify="space-between" align="center">
      <Flex
        flexDirection="row"
        mx={{
          base: 2,
          md: 2,
          lg: 5,
          xl: 5,
        }}
        justify="center"
      >
        <Avatar size="lg" src={KatoAvatar} color={"white"} bg="brand.400">
          <AvatarBadge boxSize="1.25em" bg="green.500" />
        </Avatar>
        <Flex flexDirection="column" mx="5" justify="center">
          <HStack>
            <Text fontSize="lg" fontWeight="bold">
              KATO
            </Text>
            {coins > 0 ? (
              <Badge bg={"#FFD700"}>Premium</Badge>
            ) : (
              <Badge bg={"green"} color={"white"}>
                Free
              </Badge>
            )}
          </HStack>
          <Text fontSize="sm" color="gray.500">
            The AI paralegal
          </Text>
          <Text color="green.500">Online</Text>
        </Flex>
      </Flex>
      <Flex flexDirection="column" justify="center" m={0}>
        <PDFDownloadLink
          document={<PdfContent messages={messages} />}
          fileName={sessionName + ".pdf"}
        >
          {({ blob, url, loading, error }) => {
            return (
              <Button
                href={url}
                target="_blank"
                display={{
                  base: "none",
                  md: "none",
                  lg: "block",
                  xl: "block",
                }}
                fontSize={{
                  base: "2xs",
                  md: "md",
                  lg: "md",
                  xl: "md",
                }}
              >
                Export to PDF
              </Button>
            );
          }}
        </PDFDownloadLink>
      </Flex>

      {/* <Flex flexDirection="column" mx="5" justify="center" m={0}>
        {datePicker}
      </Flex> */}
    </Flex>
  );
};

export default Header;
