import React from 'react'

function SimpleLayout({children}) {
  return (
    <>
        {children}
    </>
  )
}

export default SimpleLayout